import { Dispatch } from "react";
import { ModelError } from "../../models/model";
import User from "../../models/user.model";
import { TUserPoint } from "../../models/userPoint.model";
import { getSocketCollection } from "../../service/socketIo";
// import {  socketConnection, socketDisconnect } from "../../service/socketIo";
// import socketService from "../../service/socketIo";
import { SIGNIN, SIGNOUT, TOGGLE_MODAL_AUTH, UNVERIFY, VERIFY, TOGGLE_MODAL_WELCOME, TOGGLE_INTRODUCING_POPOVER, GET_PROFILE } from "../type";
import { EAuthType, EStep } from "@Components/modal/auth/const";
import Cookies from "js-cookie";
import { tokenKey } from "models/__variables";

export function verify() {
   return async (
      dispatch: Dispatch<{
         type: string;
         payload: {
            userInfo: User | null;
            userPoints: TUserPoint | null;
            userNotifications: {
               unseen: number;
               cartCount: number;
            } | null;
            userBookmarks: {
               total: number;
            } | null;
            userStatus: {
               emailExists: boolean;
               passwordExists: boolean;
               phoneExists: boolean;
            } | null;
         };
      }>
   ) => {
      const res = await User.verify();
      if (res instanceof ModelError) {
         dispatch({
            type: UNVERIFY,
            payload: {
               userInfo: null,
               userPoints: null,
               userNotifications: null,
               userBookmarks: null,
               userStatus: null,
            },
         });
         Cookies.remove(tokenKey);
         localStorage.removeItem(tokenKey);
      } else {
         dispatch({
            type: VERIFY,
            payload: res,
         });
      }
   };
}
export function getProfile() {
   return async (
      dispatch: Dispatch<{
         type: string;
         payload: User | null;
      }>
   ) => {
      const res = await User.getProfile();
      if (!res) return;
      if (res instanceof ModelError) {
         dispatch({
            type: GET_PROFILE,
            payload: null,
         });
      } else {
         dispatch({
            type: GET_PROFILE,
            payload: res,
         });
      }
   };
}
export function signin(data: {
   userInfo: User;
   token: string;
   userPoints: TUserPoint;
   userNotifications: {
      unseen: number;
      cartCount: number;
   };
   userBookmarks: {
      total: number;
   };
   userStatus: {
      emailExists: boolean;
      passwordExists: boolean;
      phoneExists: boolean;
   };
}) {
   // socketConnection();

   getSocketCollection();
   return async (
      dispatch: Dispatch<{
         type: string;
         payload: {
            userInfo: User;
            userPoints: TUserPoint;
            userNotifications: {
               unseen: number;
               cartCount: number;
            };
            userBookmarks: {
               total: number;
            };
            userStatus: {
               emailExists: boolean;
               passwordExists: boolean;
               phoneExists: boolean;
            };
         };
      }>
   ) => {
      dispatch({
         type: SIGNIN,
         payload: data,
      });
   };
}
export function signout() {
   // socketDisconnect();
   getSocketCollection();
   return {
      type: SIGNOUT,
   };
}
export function toggleModalAuth(payload?: {
   visible?: boolean;
   step?: EStep;
   type?: EAuthType;
   dataStep?: any;
   redirect?: string;
   cbs?: (options: { visible?: boolean; step?: EStep; type?: EAuthType; dataStep?: any }, res?: any) => void;
}) {
   return {
      type: TOGGLE_MODAL_AUTH,
      payload,
   };
}
export function togglePopoverIntroducing(payload?: { visible?: boolean; top?: number; left?: number }) {
   return {
      type: TOGGLE_INTRODUCING_POPOVER,
      payload,
   };
}

export function toggleModalWelcome(payload?: { visible?: boolean; showGreetingPost?: boolean; showFirstHashtag?: boolean }) {
   return {
      type: TOGGLE_MODAL_WELCOME,
      payload,
   };
}
