import { Model, IModel, } from "./model";

export enum EStatus {
   enable = "enable",
   disable = "disable"
}

export interface IMenuItem extends IModel {
   ["info"]: {
      ["title"]: string;
      ["slug"]: string | null;
      ["position"]: number;
   };
   ["options"]?: {
      ["status"]: EStatus;
      ["hidden"]: boolean;
   };
   ["menuId"]?: string;
   ["parentId"]?: string | null;
   ["collectionIds"]: string[];
   ["vendorIds"]: string[];
   ["moreParentIds"]: string[];

   readonly ["fullSlug"]?: string;
   readonly ["fullUrl"]?: string;
   readonly ["parentIds"]: string[];

   ["__parent"]?: IMenuItem;
   ["__childs"]: IMenuItem[];
   ["__icon"]?: string;

   ["lv"]: number; // 0 - 4
   ["xpath"]: string;
}

export class MenuItem extends Model<IMenuItem> implements IMenuItem {
   public ["info"]!: {
      ["title"]: string;
      ["slug"]: string | null;
      ["position"]: number;
   };
   public ["options"]?: {
      ["status"]: EStatus;
      ["hidden"]: boolean;
   };
   public ["menuId"]?: string;
   public ["parentId"]?: string | null;
   public ["collectionIds"]!: string[];
   public ["vendorIds"]!: string[];
   public ["moreParentIds"]: string[];

   public ["__parent"]?: MenuItem;
   public ["__childs"]: MenuItem[];

   public readonly ["fullSlug"]?: string;
   public readonly ["fullUrl"]?: string;
   public readonly ["parentIds"]: string[] = [];

   public ["__icon"]?: string;

   public ["lv"]: number;
   public ["xpath"]: string;

   constructor(data?: IMenuItem, parent?: MenuItem | null) {
      super(data);

      Object.assign(this, data);

      if (parent === null) {
         this.fullSlug = data?.info?.slug ?? "";
      } else if (parent instanceof MenuItem) {
         this.fullSlug = `${parent.fullSlug}${!parent.fullSlug ? `${data?.info?.slug ?? ""}` : `${!data?.info?.slug ? "" : `/${data.info.slug}`}`}`;
         this.parentIds.push(...parent.parentIds, parent._id);
      }

      if (this.__childs && this.__childs instanceof Array) {
         for (let i = 0; i < this.__childs.length; i++) {
            const item = this.__childs[i];

            if (!(item instanceof MenuItem) && typeof item === "object") {
               this.__childs[i] = new MenuItem(item, this);
            }
         }
      }
   }
}

export default MenuItem;
