// Auth type

export const VERIFY = "VERIFY";
export const UNVERIFY = "UNVERIFY";
export const SIGNIN = "SIGNIN";
export const SIGNOUT = "SIGNOUT";
export const GET_PROFILE = "GET_PROFILE";
export const TOGGLE_MODAL_AUTH = "TOGGLE_MODAL_AUTH";
export const TOGGLE_INTRODUCING_POPOVER = "TOGGLE_INTRODUCING_POPOVER";
export const TOGGLE_MODAL_WELCOME = "TOGGLE_MODAL_WELCOME";

//Categories type

export const CATEGORY = "CATEGORY";

export const MENU = "MENU";

//Medias by space
// export const MEDIAS_BY_SPACE = "MEDIAS_BY_SPACE";
// export const GENERAL_MEDIAS_BY_SPACE = "GENERAL_MEDIAS_BY_SPACE";
// export const ADD_MEDIA_BY_SPACE = "ADD_MEDIA_BY_SPACE";
// export const UPDATE_MEDIA_BY_SPACE = "UPDATE_MEDIA_BY_SPACE";
// export const DELETE_MEDIA_BY_SPACE = "DELETE_MEDIA_BY_SPACE";

//bookmark medias by space
export const USER_BOOKMARK_BY_SPACE = "USER_BOOKMARK_BY_SPACE";
export const UPDATE_USER_BOOKMARK_BY_SPACE = "UPDATE_USER_BOOKMARK_BY_SPACE";
export const ADD_USER_BOOKMARK_BY_SPACE = "ADD_USER_BOOKMARK_BY_SPACE";
export const DELETE_USER_BOOKMARK_BY_SPACE = "DELETE_USER_BOOKMARK_BY_SPACE";
export const APPEND_USER_BOOKMARK_BY_SPACE = "APPEND_USER_BOOKMARK_BY_SPACE";

export const UTM_PARAMS = "UTM_PARAMS";

// social Feed
export const SOCIAL_FEED = "SOCIAL_FEED";
export const UPDATE_SOCIAL_FEED = "UPDATE_SOCIAL_FEED";
export const REMOVE_SOCIAL_FEED = "REMOVE_SOCIAL_FEED";
export const ADD_FRIST_SOCIAL_FEED = "ADD_FRIST_SOCIAL_FEED";
export const SET_TOTAL_SOCIAL_FEED = "SET_TOTAL_SOCIAL_FEED";
export const USER_FOLLOWING = "USER_FOLLOWING";
export const REMOVE_USER_FOLLOWING = "REMOVE_USER_FOLLOWING";
export const ADD_USER_FOLLOWING = "ADD_USER_FOLLOWING";
export const APPEND_USER_FOLLOWING = "APPEND_USER_FOLLOWING";
export const OPEN_MODAL_POST = "OPEN_MODAL_POST";

//ab testing
export const ABTEST = "ABTEST";
