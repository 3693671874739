/* eslint-disable no-prototype-builtins */
/* eslint-disable no-var */
import Moment from "moment";
import Resizer from "react-image-file-resizer";
import { ISizeMedia } from "../models/socialFeedMedia.model";
import "moment/locale/vi";
// Moment.locale("vi");

export const isServer = typeof window === "undefined";
export const isClient = !isServer;

export enum ETypeArticle {
   community = "community",
   actualWork = "actualWork",
   designLibrary = "designLibrary",
}
export const COLORS_NAME: any = {
   trang: "Trắng",
   den: "Đen",
   "xanh-la": "Xanh lá",
   "xanh-duong": "Xanh dương",
   nau: "Nâu",
   xam: "Xám",
   vang: "Vàng",
   cam: "Cam",
   tim: "Tím",
   "xanh-mint": "Xanh mint",
   be: "Be",
   hong: "Hồng",
   do: "Đỏ",
};
export const nameOfStyles: any = {
   bohemian: "Bohemian",
   indochine: "Indochine",
   japandi: "Japandi",
   "neo-classic": "Neo-classic",
   industrial: "Industrial",
   "color-block": "Color Block",
   scandinavian: "Scandinavian",
   minimalist: "Minimalist",
   modern: "Modern",
   khac: "khác",
};
export const nameOfStylesById: any = {
   "61515809f0dd56b72502bfe7": "Bohemian",
   "615157fff0dd56b72502bfe3": "Indochine",
   "615157eaf0dd56b72502bfdf": "Japandi",
   "615157daf0dd56b72502bfdb": "Neo-classic",
   "615157cdf0dd56b72502bfd7": "Industrial",
   "6150d8fdafe497b05e32d75a": "Color Block",
   "615018c477e0e9a41c6ba695": "Scandinavian",
   "615018ba77e0e9a41c6ba691": "Minimalist",
   "615018b277e0e9a41c6ba68d": "Modern",
   "6153df983486eef66bdc2d08": "khác",
};
export const TYPE_PROJECT: any = {
   "can-ho-chung-cu": "Chung cư",
   "nha-pho": "Nhà phố",
   "biet-thu": "Biệt thự",
   office: "Văn phòng",
   commercial: "Thương mại",
   duplex: "duplex",
   khac: "Khác",
};

export const DESIGN_TYPE: any = {
   threeD: "3D",
   twoD: "2D",
   sketchUp: "SketchUp",
};
export const COLORS = [
   { name: "Đen", hex: "#000000" },
   { name: "Xám", hex: "#808080" },
   { name: "Xám đậm", hex: "#757575" },
   { name: "Xám nhạt", hex: "#d2dae2" },
   { name: "Trắng", hex: "#ffffff", special: true, border: "#e8eaed" },
   { name: "Xanh dương", hex: "#2b67c2" },
   { name: "Xanh mint", hex: "#57bdbb" },
   { name: "Xanh da trời", hex: "#73c7ff" },
   { name: "Xanh lá", hex: "#97c292" },
   { name: "Tím", hex: "#b19bbf" },
   { name: "Hồng", hex: "#e9bacb" },
   { name: "Đỏ", hex: "#df7070" },
   { name: "Cam", hex: "#ebb155" },
   { name: "Vàng", hex: "#f5e23b" },
   { name: "Nâu đậm", hex: "#825b2c" },
   { name: "Nâu nhạt", hex: "#c2966d" },
   { name: "Be", hex: "#ede0be" },
];

export function currency(number?: number | string | null): string {
   if (isEmpty(number)) return "0";
   const num = new Intl.NumberFormat("vi").format(+number!);
   return num;
}

export function dotNum(number: number) {
   // if (number < 10000) {
   //     return number
   // }
   return new Intl.NumberFormat("vi").format(number);
}

export const formatNumber = (number?: number) => {
   if (!number) return "0";
   return new Intl.NumberFormat("vi").format(number);
   // return new Intl.NumberFormat('vn').format(number);
};

export function kFormatter(number: number, type?: ETypeArticle) {
   return Math.abs(number) > 999999 && Math.abs(number) <= 999999999
      ? Math.sign(number) * parseFloat((Math.abs(number) / 1000000).toFixed(1)) + `${type === ETypeArticle.community ? " Triệu" : " Triệu đồng"}`
      : Math.abs(number) > 999999999
      ? Math.sign(number) * parseFloat((Math.abs(number) / 1000000000).toFixed(1)) + `${type === ETypeArticle.community ? " Tỉ" : " Tỉ đồng"}`
      : Math.sign(number) * Math.abs(number);
}

export function mFormatter(number: number) {
   return Math.abs(number) > 999999999
      ? Math.sign(number) * parseFloat((Math.abs(number) / 1000000000).toFixed(1)) + " Tỉ đồng"
      : Math.sign(number) * Math.abs(number);
}

export function nFormatter(num, digits?: number) {
   const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
   ];
   const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
   const item = lookup
      .slice()
      .reverse()
      .find((item) => num >= item.value);

   if (!item) return "0";
   return new Intl.NumberFormat("vi")
      .format(+(num / item.value).toFixed(digits ?? 3))
      .replace(regexp, "")
      .concat(item.symbol);
}

export function convertQueryStringToObj() {
   var search = window.location.search.substring(1);
   return !search ? {} : JSON.parse('{"' + decodeURI(search.replace(/&/g, '","').replace(/=/g, '":"')) + '"}');
}

export function convertObjToQueryString(obj: any) {
   var str: any[] = [];
   for (var p in obj)
      if (obj.hasOwnProperty(p)) {
         str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
   return str.join("&");
}

export function updateHeader({ title, description, canonical, image }: { title: string; description: string; canonical: string; image: string }) {
   const titleElm = typeof document !== "undefined" && document.querySelector("title");
   const ogTitleElm = typeof document !== "undefined" && document.querySelector('meta[property="og:title"]');
   const descriptionElm = typeof document !== "undefined" && document.querySelector('meta[name="description"]');
   const ogDescriptionElm = typeof document !== "undefined" && document.querySelector('meta[property="og:description"]');
   const imageElm = typeof document !== "undefined" && document.querySelector('meta[property="og:image"]');
   const linkElm = typeof document !== "undefined" && document.querySelector('link[rel="canonical"]');
   const urlEl = typeof document !== "undefined" && document.querySelector('meta[property="og:url"]');
   titleElm && (titleElm.innerText = title);
   ogTitleElm && ogTitleElm.setAttribute("content", title);
   descriptionElm && descriptionElm.setAttribute("content", description);
   ogDescriptionElm && ogDescriptionElm.setAttribute("content", description);
   imageElm && imageElm.setAttribute("content", image);
   linkElm && linkElm.setAttribute("href", canonical);
   urlEl && urlEl.setAttribute("content", canonical);
}

export function bytesToSize(bytes: number) {
   var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
   if (bytes == 0) return "0 Byte";
   const measure = 1024;
   var i = Math.floor(Math.log(bytes) / Math.log(measure));
   return Math.round(bytes / Math.pow(measure, i)) + sizes[i];
}

export const resizeImage = (file: File) =>
   new Promise((resolve) => {
      Resizer.imageFileResizer(
         file,
         2880,
         2880,
         "jpeg",
         90,
         0,
         (uri) => {
            resolve(uri);
         },
         "file",
         150,
         150
      );
   });

export const getSizeImage = (image: File) =>
   new Promise((resolve, reject) => {
      const reader = new FileReader();
      const result: ISizeMedia = { width: 0, height: 0 };
      reader.onload = (e) => {
         const img = new Image();
         if (e?.target?.result) {
            img.src = e.target.result as string;
            img.onload = () => {
               result.width = img.width;
               result.height = img.height;
               resolve(result);
            };
            img.onerror = () => resolve(result);
         }
      };
      reader.onerror = () => resolve(result);
      reader.readAsDataURL(image);
   });

export function abbreviateNumber(value: number) {
   return new Intl.NumberFormat("en-gb", {
      notation: "compact",
      compactDisplay: "short",
   }).format(value);
}

export function getTimeFromNow(date: string | number) {
   if (new Date(date).getFullYear() !== new Date().getFullYear()) {
      return Moment(date).format("DD/MM/YYYY");
   } else if (Moment().startOf("day").diff(Moment(date).startOf("day"), "days") > 6) {
      return Moment(date).format("DD/MM");
   } else if (new Date(date).getTime() > new Date().getTime()) {
      if (Moment(date).startOf("day").diff(Moment().startOf("day"), "days") > 6) {
         return Moment(date).format("DD/MM");
      }
      return Moment(date).fromNow();
   } else {
      const now = Moment();
      const target = Moment(date);

      const diffMilliseconds = now.diff(target);
      const diffDuration = Moment.duration(diffMilliseconds);

      const years = diffDuration.years();
      const months = diffDuration.months();
      const days = diffDuration.days();
      const hours = diffDuration.hours();
      const minutes = diffDuration.minutes();
      let displayText = "";

      if (years > 0) {
         displayText += years + " năm ";
         return (displayText += "trước");
      }
      if (months > 0) {
         displayText += months + " tháng ";
         return (displayText += "trước");
      }
      if (days > 0) {
         displayText += days + " ngày ";
         return (displayText += "trước");
      }
      if (hours > 0) {
         if (hours === 23) {
            if (minutes >= 0 && minutes < 30) {
               displayText += hours + " giờ ";
               return (displayText += "trước");
            } else {
               return (displayText += "1 ngày trước");
            }
         } else {
            displayText += hours + " giờ ";
            displayText += "trước";
            return displayText;
         }
      }
      if (minutes > 0) {
         displayText += minutes + " phút ";
         return (displayText += "trước");
      }
      if (minutes === 0) {
         return (displayText += "vài giây trước");
      }
   }
}

export function truncate(text: string, limit: number) {
   return text.length > limit + 3 ? `${text.slice(0, limit)}...` : text;
}

export function isEmpty(obj?: any): boolean {
   if (obj == undefined || obj == null || Number.isNaN(obj)) return true;
   if (typeof obj === "object") {
      if (Object.entries(obj).length > 0) return false;
      return true;
   }
   return false;
}

export const replaceSpecialCharactersAndEmojis = (val: string) => {
   let newValue = val.replace(/\s+/g, " ");
   const regexSpecialCharacters = /[-'/`~!*$@_%+=.,^&(){}[\]|;:“<>?\\]/g;
   const regexEmojis =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
   if (regexSpecialCharacters.test(newValue)) {
      newValue = newValue.replace(regexSpecialCharacters, "");
   } else if (regexEmojis.test(newValue)) {
      newValue = newValue.replace(regexEmojis, "");
   } else {
      newValue = val;
   }
   return newValue;
};

export async function getLocalIP(): Promise<string> {
   return await fetch("https://api.ipify.org?format=json")
      .then(async (data) => {
         return (await data.json()).ip;
      })
      .catch((err) => {
         console.log(err);
      });
}

export function hideItemBbyId(id, count: number = 0) {
   if (!id) return false;
   const footer = document.getElementById(id);
   if (footer) {
      footer.style.display = "none";
      return true;
   } else if (count <= 3) {
      setTimeout(() => {
         hideItemBbyId(id, (count += 1));
      }, 0);
   } else {
      return false;
   }
}

export function debounce(func, delay) {
   let timeout;

   return function executedFunc(...args) {
      if (timeout) {
         clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
         func(...args);
         timeout = null;
      }, delay);
   };
}

export function throttle(func, limit) {
   let inThrottle = false;
   let lastArgs;
   let lastContext;

   return function (context, ...args) {
      if (!inThrottle) {
         func.apply(context, args);
         lastArgs = args;
         lastContext = context;
         inThrottle = true;
         setTimeout(() => {
            inThrottle = false;
            if (lastArgs) {
               func.apply(lastContext, lastArgs);
               lastArgs = null;
               lastContext = null;
            }
         }, limit);
      } else {
         lastArgs = args;
         lastContext = context;
      }
   };
}

export function replaceAllDuplicateItem<T>(arr: T[], key: string) {
   return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export function clearAllStyle(str?: string | null): string {
   if (!str) return "";
   // return str
   return str.replaceAll(/style="(.*)"/gi, "");
}
