import React, { MouseEventHandler } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";
import Link from "@Components/Link";
import MenuItem from "../../models/menuItem.model";
import styles from "./_scss/index.module.scss";

interface ICustomProps {
   id?: string;
   isNew?: boolean;
   showDropdown?: boolean;
   ["className"]?: string;
   ["parentMenu"]?: MenuItem;
   ["menuItem"]: MenuItem;
   ["onClick"]?: MouseEventHandler<HTMLAnchorElement>;
   ["onMouseEnter"]?: MouseEventHandler<HTMLAnchorElement>;
   onMouseLeave?: MouseEventHandler<HTMLAnchorElement>;
   isActive?: boolean;
}

export default function MenuItemElement({ isNew, menuItem, parentMenu, showDropdown, className, isActive, onMouseEnter, onMouseLeave, onClick }: ICustomProps) {
   const router = useRouter();

   if (menuItem.info.slug === "thi-cong-noi-that") return <></>;

   const renderHref = () => {
      if (menuItem.info.slug) {
         if (parentMenu?.info?.slug === "shop") {
            if (menuItem.info?.slug === null || menuItem.info?.slug === "shop") {
               return `/shop`;
            } else {
               return `/shop/${menuItem.info?.slug}`;
            }
         } else {
            return `/${menuItem.info.slug}`;
         }
      } else {
         if (menuItem.__childs?.[0]?.info.slug) {
            return `/${menuItem.__childs?.[0]?.info.slug ?? ""}`;
         } else {
            return parentMenu?.fullSlug ? `/${parentMenu?.fullSlug}` : `/${parentMenu?.info?.slug}`;
         }
      }
   };
   return (
      <Link
         href={renderHref()}
         className={classNames([
            className,
            isActive || (router.pathname === "/thi-cong-noi-that" && menuItem.info.slug === "thiet-ke-noi-that") ? styles["active"] : "",
         ])}
         onMouseEnter={onMouseEnter ?? undefined}
         onMouseLeave={onMouseLeave ?? undefined}
         onClick={onClick ?? undefined}
         id={menuItem.info.slug ?? undefined}
         style={{ marginRight: isNew ? "26px" : "" }}
      >
         {isNew && <div className={styles["tag-new"]}>Mới</div>}
         <div className="d-flex align-items-center">
            <span>{menuItem?.info?.title}</span>
            {showDropdown && (
               <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                  <path d="M1 1.64844L6.00081 6.22844L11 1.64844" stroke="#252A2B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
               </svg>
            )}
         </div>
      </Link>
   );
}
