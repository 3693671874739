import Link from "next/link";
import React from "react";
import LayoutContainer from "../Container";

interface IState {
   active: string;

   offCanvasStatus: boolean;
   offCanvasMenuStatus: boolean;

   offCanvasRightStatus: boolean;
   offCanvasRightMenuStatus: boolean;
}
interface IProps {
}

export const enum EIdLandingPage {
   rule = 'the-le',
   referral = 'tham-gia',
   main = 'trang-chu'
}

export default class HeaderSecondary extends React.Component<IProps & any, IState> {
   public readonly _menu = [
      { slug: 'trang-chu', title: 'Trang chủ', hash: `#${EIdLandingPage.main}`, id: EIdLandingPage.main },
      { slug: 'tham-gia', title: 'Tham gia', hash: `#${EIdLandingPage.referral}`, id: EIdLandingPage.referral },
      { slug: 'the-le', title: 'Thể lệ ', hash: `#${EIdLandingPage.rule}`, id: EIdLandingPage.rule },
   ];
   constructor (props: IProps & any) {

      super(props);
      this.state = {
         active: '',
         offCanvasStatus: false,
         offCanvasMenuStatus: false,

         offCanvasRightStatus: false,
         offCanvasRightMenuStatus: false,
      };

   }

   async componentDidMount() {
      const hash = `#${this.props.router.asPath.split('#')[1]}`
      if (!hash) return;
      const elSection = document.querySelector(hash);
      elSection?.scrollIntoView({ behavior: 'smooth' });
   }

   async componentDidUpdate(prevProps: any, prevState: IState) {
      const hash = `#${this.props.router.asPath.split('#')[1]}`

      if (this.props.router.asPath.split('#')[1] !== prevProps.router.asPath.split('#')[1]) {
         this.setState({ active: hash });
         return;
      }
   }

   private _disableOffCanvasMenu() {
      window.scrollTo({ left: 0, top: 0 });
      // Off-canvas status
      if (this.state.offCanvasStatus) {
         this.setState({
            offCanvasStatus: false
         });
      }

      //#region [Right]
      if (this.state.offCanvasRightStatus) {
         this.setState({
            offCanvasRightStatus: false
         });
      }
      //#end region

      setTimeout(() => {
         const updates = {};
         Object.assign(updates,
            this.state.offCanvasRightMenuStatus ? undefined : { offCanvasRightMenuStatus: false });

         this.setState(updates);
      }, 400);

      if (!this.state.offCanvasMenuStatus) {
         return;
      }

      setTimeout(() => {
         this.setState({
            offCanvasMenuStatus: false
         });
      }, 500);

   }

   private _enableOffCanvasRightMenu() {
      this._enableOffCanvasMenu();

      this.setState({
         offCanvasRightMenuStatus: true
      });

      setTimeout(() => {
         this.setState({
            offCanvasRightStatus: true
         });
      }, 100);

   }

   private _enableOffCanvasMenu() {
      if (!this.state.offCanvasMenuStatus) {
         this.setState({
            offCanvasMenuStatus: true
         });

         if (!this.state.offCanvasStatus) {
            setTimeout(() => {
               this.setState({
                  offCanvasStatus: true
               });
            }, 100);
         }
      }
   }

   private _stopPropagation(e: React.MouseEvent<HTMLElement>) {
      e.stopPropagation();
   }

   render() {
      return (
         <header className="spacet-main-header spacet-landing-page-header">
            <div className="top">
               <LayoutContainer className="container_custom">

                  <div className="d-flex align-items-center flex-nowrap justify-content-between gx-2 header">
                     <div className="d-flex align-items-center">
                        <Link onClick={() => { window.scrollTo({ left: 0, top: 0 }); }} href="/"><img src="/assets/img/icon/logo.svg" width={112} height={28} alt="Space T Việt Nam" /></Link>
                        <ul className="menu d-none d-md-flex align-items-center list-unstyled mb-0 h-100">
                           {
                              this._menu?.map((menuItem, i) => {
                                 return (

                                    <Link
                                       key={i}
                                       className={`menu-item ${(this.state.active === menuItem.hash) || (!this.state.active && menuItem.id === EIdLandingPage.main) ? 'menu-item-active' : ''}`}
                                       // smooth
                                       // to={menuItem.hash}
                                       href={`/chuong-trinh-gioi-thieu-ban/${menuItem.hash}`}
                                    >
                                       {menuItem.title}
                                    </Link>
                                 );
                              })
                           }
                        </ul>
                     </div>
                     <Link onClick={() => { window.scrollTo({ left: 0, top: 0 }); }}
                        href="/cau-hoi-khao-sat"
                        className="online-consultation-button text-truncate mb-2 mb-sm-0 d-none d-md-block"
                        title="Kết nối chuyên gia">Kết nối chuyên gia</Link>
                     <div className="d-block d-md-none">
                        <button className="hamburger-button" title="Menu"><img src="/assets/img/icon/hamburger.svg" alt="Menu" onClick={this._enableOffCanvasRightMenu.bind(this)} /></button>
                     </div>
                  </div>
               </LayoutContainer>
               {
                  (() => {
                     if (!this.state.offCanvasMenuStatus) {
                        return;
                     }
                     return (
                        <div className={!this.state.offCanvasStatus ? "off-canvas-menu screen-height-100" : "off-canvas-menu screen-height-100 enable"} onClick={this._disableOffCanvasMenu.bind(this)}>
                           {
                              (() => {
                                 if (!this.state.offCanvasRightMenuStatus) {
                                    return;
                                 }
                                 return (
                                    <div className={!this.state.offCanvasRightStatus ? "right-menu overflow-hidden-auto screen-height-100" : "right-menu overflow-hidden-auto screen-height-100 enable"} onClick={this._stopPropagation}>
                                       <div className="header sticky-top">
                                          <LayoutContainer>
                                             <div className="row flex-wrap align-items-center">
                                                <div className="col" style={{ paddingLeft: "20px" }}>
                                                   <img src="/assets/img/icon/logo.svg" width={112} alt="Space T Việt Nam" />
                                                </div>
                                                <div className="col-auto">
                                                   <button className="close">
                                                      <img
                                                         style={{ opacity: 0.6 }}
                                                         src="/assets/img/icon/remove.svg"
                                                         alt="Đóng"
                                                         onClick={this._disableOffCanvasMenu.bind(this)} />
                                                   </button>
                                                </div>
                                             </div>
                                          </LayoutContainer>
                                       </div>
                                       <div className="body">
                                          <ul className="main-mobile-menu d-flex flex-column list-unstyled" style={{ marginBottom: "6.25rem" }}>
                                             <li>
                                                <Link href="/cau-hoi-khao-sat" className="online-consultation" title="Kết nối chuyên gia" onClick={this._disableOffCanvasMenu.bind(this)}>Kết nối chuyên gia</Link>
                                             </li>
                                             {
                                                this._menu?.map((menuItem, i) => {
                                                   return (
                                                      <li className="position-relative" key={i}>
                                                         <a title={menuItem.title} href={menuItem.hash} className={`text-uppercase ${(this.state.active === menuItem.hash) || (!this.state.active && menuItem.id === EIdLandingPage.main) ? 'active' : ''}`} onClick={this._disableOffCanvasMenu.bind(this)}>
                                                            {menuItem.title}
                                                         </a>
                                                      </li>
                                                   );
                                                })
                                             }
                                          </ul>
                                       </div>

                                    </div>
                                 );
                              })()
                           }
                        </div>
                     );
                  })()
               }
            </div>
         </header>
      );
   }
}
