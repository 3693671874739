import uniqBy from "lodash/uniqBy";
import SocialFeed, { EBannerType, ESocialFeedType } from "../../models/socialFeed.model";
import User from "../../models/user.model";
import {
   ADD_FRIST_SOCIAL_FEED,
   ADD_USER_FOLLOWING,
   APPEND_USER_FOLLOWING,
   REMOVE_USER_FOLLOWING,
   SOCIAL_FEED,
   UPDATE_SOCIAL_FEED,
   USER_FOLLOWING,
   OPEN_MODAL_POST,
   REMOVE_SOCIAL_FEED,
   SET_TOTAL_SOCIAL_FEED
} from "../type";

const initState = {
   data: [],
   total: 0,
   userFollowing: [],
   modalPost: {
      visible: false,
      showFirstHashtag: false,
      type: ESocialFeedType.socialFeed,
      bannerType: EBannerType.banner1,
      data: null
   }
};
export default function socialFeedReducer(state = initState, action: { payload?: any; [t: string]: any }) {
   const { payload } = action;
   switch (action.type) {
      case SOCIAL_FEED:
         return {
            ...state,
            data: [...(action?.payload ?? [])]
         };
      case UPDATE_SOCIAL_FEED:
         return {
            ...state,
            data: state.data.map((item: SocialFeed) => (item._id == action.payload._id ? action.payload : item))
         };
      case ADD_FRIST_SOCIAL_FEED:
         return {
            ...state,
            data: [action.payload, ...state.data]
         };
      case REMOVE_SOCIAL_FEED:
         const socialFeed = action.payload as SocialFeed;
         return {
            ...state,
            data: state.data.filter((item: SocialFeed) => item._id !== socialFeed._id)
         };

      case SET_TOTAL_SOCIAL_FEED:
         return {
            ...state,
            total: payload
         };

      //user following
      case USER_FOLLOWING:
         return {
            ...state,
            userFollowing: uniqBy(action.payload, "_id")
         };
      case ADD_USER_FOLLOWING:
         return {
            ...state,
            userFollowing: uniqBy([action.payload, ...state.userFollowing], "_id")
         };
      case REMOVE_USER_FOLLOWING:
         return {
            ...state,
            userFollowing: state.userFollowing.filter((item: User) => item._id !== action.payload._id)
         };
      case APPEND_USER_FOLLOWING:
         return {
            ...state,
            userFollowing: uniqBy([...(action.payload ?? []), ...state.userFollowing], "_id")
         };

      case OPEN_MODAL_POST:
         return {
            ...state,
            modalPost: {
               ...payload,
               visible: payload.visible ?? state.modalPost.visible,
               type: payload.type ?? state.modalPost.type,
               bannerType: payload.bannerType ?? state.modalPost.bannerType,
               data: payload.data ?? state.modalPost.data
            }
         };
      default:
         return state;
   }
}
