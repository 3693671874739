/**
 * Model(s)
 */
import { cloudFrontHost } from "./__variables";
import { IModel, Model } from "./model";
import { EMediaStatus, } from "./modelMedia.model";
import Reaction, { EReactionTypes } from "./reaction.model";
import User from "./user.model";
import UserSubscribe from "./userSubscribe.model";

export enum ECommentSortBy {
   id = "_id",
   createdAt = "createdAt",
   updatedAt = "updatedAt"
}

export enum ECommentStatus {
   enable = "enable",
   disable = "disable"
}

export enum ECommentType {
   'comment' = "Comment"
}

export interface IComment<T extends Model = Model> extends IModel {
   ["info"]: {
      ["content"]: string | null;
   };
   ["image"]?: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["width"]: number | null;
      ["height"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
      ["position"]: number | null;
      ["status"]: EMediaStatus
   };
   ["options"]: {
      ["status"]: ECommentStatus;
   };
   ["userId"]: string | null;
   ["replyToUserIds"]: string[];
   ["__user"]?: User | null;
   ["__replyToUsers"]?: User[];
   ["__reactionCount"]?: {
      [key in EReactionTypes]?: number;
   } | number;
   ["__currentUserReactionsMap"]?: {
      [key in EReactionTypes]?: Reaction;
   };
   ["__replies"]?: T[];
   ["__subscribedByCurrentUser"]?: UserSubscribe;
}

export class Comment<T extends IComment = IComment> extends Model {
   public ["info"]!: {
      ["content"]: string | null;
   };
   public ["image"]?: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["width"]: number | null;
      ["height"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
      ["position"]: number | null;
      ["status"]: EMediaStatus
   };
   public ["options"]!: {
      ["status"]: ECommentStatus;
   };
   public ["userId"]!: string | null;
   public ["replyToUserIds"]!: string[];

   public ["__user"]!: User | null;
   public ["__replyToUsers"]!: User[];
   public ["__reactionCount"]?: {
      [key in EReactionTypes]?: number;
   } | number;
   public ["__replyCount"]!: number;
   public ["__currentUserReactionsMap"]?: {
      [key in EReactionTypes]?: Reaction;
   };
   public ["__replies"]?: T[];
   public ["__subscribedByCurrentUser"]?: UserSubscribe;

   public ["$url"]?: any | null;
   // public ["$urlXSResizeMax"]?: any | null;
   // public ["$urlSMResizeMax"]?: any | null;
   // public ["$urlMDResizeMax"]?: any | null;
   // public ["$urlLGResizeMax"]?: any | null;
   constructor(data?: T) {
      super(data);
      Object.assign(this, data);

      this.$url = `${this?.image?.protocol}://${this?.image?.domain}/${this?.image?.container}/${this._id}${this?.image?.extension}`;
   }

}

export default Comment;