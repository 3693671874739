/**
 * Core(s)
 */
import Model, { ModelError, Paginate, TPaginate, TPayload } from "./model";
import Product from "./product.model";
import { apiDomain, appDomain, cloudFrontHost, tokenKey } from "./__variables";
import { resizeImage } from "utils/resizeImage";

export enum EProductTaggingImageSortBy {
   createdAt = "createdAt",
}

export enum EProductTaggingImageStatus {
   unverified = "unverified",
   enable = "enable",
   delete = "delete",
}

export enum EProductTaggingImageProductTypes {
   internal = "internal",
   external = "external",
}

export enum ESolutionResizeImage {
   xs = "150",
   sm = "320",
   md = "640",
   lg = "1440",
}

export interface IProductTaggingImage extends Model {
   ["image"]: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
      ["status"]: string | null;
   };
   ["options"]: {
      ["status"]: EProductTaggingImageStatus;
      ["similar"]: boolean;
   };
   ["position"]: {
      ["topPercent"]: number;
      ["leftPercent"]: number;
   };
   ["imageTarget"]: {
      ["doc"]: string | null;
      ["model"]: string | null;
   };
   ["postTarget"]: {
      ["doc"]: string | null;
      ["model"]: string | null;
   };
   ["product"]: {
      ["productType"]: EProductTaggingImageProductTypes;
      ["url"]: string | null;
      ["productId"]: string | null;
      ["productName"]: string | null;
      ["productPrice"]: number | null;
   };
   ["__product"]?: Product;
   ["$url"]?: any | null;
   ["$url5And6ColResizeMax"]?: any | null;
}

export default class ProductTaggingImage extends Model<IProductTaggingImage> implements IProductTaggingImage {
   public ["image"]!: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
      ["status"]: string | null;
      ["isMigrated"]: boolean | null;
   };
   public ["options"]!: {
      ["status"]: EProductTaggingImageStatus;
      ["similar"]: boolean;
      ["isMigrated"]: boolean | null;
   };
   public ["position"]!: {
      ["topPercent"]: number;
      ["leftPercent"]: number;
   };
   public ["imageTarget"]!: {
      ["doc"]: string | null;
      ["model"]: string | null;
   };
   public ["postTarget"]!: {
      ["doc"]: string | null;
      ["model"]: string | null;
   };
   public ["product"]!: {
      ["productType"]: EProductTaggingImageProductTypes;
      ["url"]: string | null;
      ["productId"]: string | null;
      ["productName"]: string | null;
      ["productPrice"]: number | null;
   };
   public ["__product"]?: Product;
   public ["$url"]?: any | null;
   public ["$url64ResizeMax"]?: any | null;
   public ["$url5And6ColResizeMax"]?: any | null;

   constructor(data?: IProductTaggingImage) {
      super(data);

      Object.assign(this, data);

      const itemInfo = this.product.productType === EProductTaggingImageProductTypes.internal ? this.__product?.__images[0].info : this.image;
      const { $url, $url64ResizeMax, $url256ResizeMax } = resizeImage(this._id, itemInfo, itemInfo?.isMigrated);
      this.$url = $url;
      this.$url64ResizeMax = $url64ResizeMax;
      this.$url5And6ColResizeMax = $url256ResizeMax;
   }

   get urlTagging() {
      let url;
      if (this.product.productType === EProductTaggingImageProductTypes.internal) {
         url = this.__product?.info?.slug ? `/shop/${this.__product?.info.slug}` : undefined;
         if (url && !url.startsWith(appDomain)) {
            url = `${appDomain}${url}`;
         }
      } else {
         url = this.product.url ?? undefined;
      }
      if (url && url.startsWith(appDomain)) {
         return url;
      }
      return url;
   }

   static async paginate({
      page,
      limit,
      sort,
      sortBy,
      socialFeedId,
      socialFeedImageId,
   }: {
      page?: number;
      limit?: number;
      sortBy?: "createdAt" | "updatedAt";
      sort?: "desc" | "asc";
      socialFeedId: string;
      socialFeedImageId: string;
   }) {
      try {
         const response = await Model.fetchData({
            method: "GET",
            endPoint: "product/tagging/paginate",
            headers: {
               ["sort"]: sort ?? "desc",
               ["sort-by"]: sortBy ?? "createdAt",
               ["social-feed-id"]: socialFeedId,
               ["social-feed-image-id"]: socialFeedImageId,
               ...(limit ? { limit: String(limit) } : undefined),
               ...(page ? { page: String(page) } : undefined),
            },
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            paginator: TPaginate<IProductTaggingImage>;
         }>;

         return new Paginate(payload.data.paginator, ProductTaggingImage);
      } catch (err) {
         return err;
      }
   }

   static async createTagging({
      productType,
      productId,
      communityPostId,
      communityPostImageId,
      socialFeedId,
      socialFeedImageId,
      topPercent,
      leftPercent,
      productUrl,
      productName,
      productPrice,
      similar,
   }: {
      productType: "internal" | "external";
      productId?: string;
      communityPostId?: string;
      communityPostImageId?: string;
      socialFeedId?: string;
      socialFeedImageId?: string;
      topPercent: number;
      leftPercent: number;
      productUrl?: string;
      productName?: string;
      productPrice?: number;
      similar?: boolean;
   }) {
      let product;
      if (productType === "internal") {
         product = {
            productType: "internal",
            productId,
         };
      } else {
         product = {
            productType: "external",
            url: productUrl,
            productName,
            productPrice,
         };
      }
      try {
         const response = await Model.fetchData({
            method: "POST",
            endPoint: "product/tagging",
            isRequiredAuthorization: true,
            body: {
               socialFeedImageID: socialFeedImageId,
               socialFeedID: socialFeedId,
               communityPostImageID: communityPostImageId,
               communityPostID: communityPostId,
               position: {
                  topPercent,
                  leftPercent,
               },
               product,
               options: {
                  similar,
               },
            },
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            newProduct: ProductTaggingImage;
         }>;

         return new ProductTaggingImage(payload.data.newProduct);
      } catch (err) {
         return new ModelError(err);
      }
   }

   static async createTaggingImage({ productTaggingImageId, file }: { productTaggingImageId: string; file: File }) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "You don't have permission to access this API.",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Upload ảnh không thành công.",
                     },
                  ],
               },
            });
         }
         const formData = new FormData();
         formData.append("productTaggingImageId", productTaggingImageId);
         formData.append("file", file);
         const response = await fetch(`${apiDomain}/www/product/tagging/image`, {
            ["method"]: "POST",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
            },
            ["body"]: formData,
         });
         // const response = await Model.fetchData({
         //    method: 'POST',
         //    endPoint: 'product/tagging/image',
         //    isRequiredAuthorization: true,
         //    body: formData
         // })

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            newTaggingImage: ProductTaggingImage;
         }>;

         return new ProductTaggingImage(payload.data.newTaggingImage);
      } catch (err) {
         return err;
      }
   }

   static async deleteTagging({ id }: { id: string }) {
      try {
         const response = await Model.fetchData({
            method: "DELETE",
            endPoint: "product/tagging",
            isRequiredAuthorization: true,
            headers: {
               id,
            },
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            deletedProductTagging: ProductTaggingImage;
         }>;

         return new ProductTaggingImage(payload.data.deletedProductTagging);
      } catch (err) {
         return err;
      }
   }
}
