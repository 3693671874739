import { useAppStoreContext } from "@Components/AppStoreContext";
import { PropsWithChildren, HTMLAttributes, HTMLAttributeAnchorTarget } from "react";
import NextLink from "next/link";

interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
   href?: string | null;
   title?: string;
   rel?: string;
   target?: HTMLAttributeAnchorTarget;
   nextLink?: boolean;
}

const Link = ({ href, nextLink = true, children, onClick, ...props }: PropsWithChildren<LinkProps>) => {
   const { toggleProgressbar } = useAppStoreContext();
   const isDefaultLink = href?.startsWith("http");
   if (!isDefaultLink && href && nextLink) {
      return (
         <NextLink
            href={href}
            onClick={(e) => {
               onClick?.(e);
               if (href) {
                  toggleProgressbar();
               }
            }}
            {...props}
         >
            {children}
         </NextLink>
      );
   }
   return (
      <a
         {...(href ? { href } : undefined)}
         onClick={(e) => {
            onClick?.(e);
            if (href) {
               toggleProgressbar();
            }
         }}
         {...props}
      >
         {children}
      </a>
   );
};

export default Link;
