import { Fragment, useState } from "react";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import Button from "@Components/button";
import useCustomHookState from "hook/useCustomHookState";
import { DateOrTimeView } from "@mui/x-date-pickers/models";
import { getTimeFromNow } from "utils";

export default function SchedulePostMb({ value, onSubmit }: { value?: string; onSubmit?: any }) {
   const initDate = moment(new Date().getTime() + 1000 * 60 * 30);
   const [date, setDate] = useState<any>(value ? moment(value) : initDate);
   const { state, setState } = useCustomHookState<{
      loading?: boolean;
      view?: DateOrTimeView;
   }>({});
   const handleSubmit = async () => {
      setState({ loading: true });
      await onSubmit(moment(date).toLocaleString());
      setState({ loading: false });
   };

   return (
      <div className="schedule-post-section-mb">
         <div className="body">
            <div className="px-3 py-3">
               <div className="text-center fs-14 ">Chia sẻ của bạn sẽ được đăng vào lúc</div>
               <div className="text-center fs-14 fw-600">
                  {
                     moment(date).format("DD/MM/YYYY HH:mm")
                     // getTimeFromNow(date)
                  }
               </div>
            </div>

            <div className="wrap-calendar">
               <div className="schedule-date-time-custom-mb">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                     <StaticDateTimePicker
                        orientation="portrait"
                        reduceAnimations
                        ampm={false}
                        minutesStep={1}
                        slots={{
                           toolbar: () => <Fragment />,
                           actionBar: () => <Fragment />,
                        }}
                        sx={{
                           ".MuiTimeClock-root .MuiClock-pin": {
                              background: "#a1cbf7 !important",
                           },
                           ".MuiTimeClock-root .MuiClockPointer-root": {
                              background: "#a1cbf7 !important",
                           },
                           ".MuiTimeClock-root .Mui-selected": {
                              color: "#007AFF !important",
                           },
                           ".MuiTimeClock-root .MuiClockPointer-thumb": {
                              border: "16px solid #a1cbf7 !important",
                              background: "#a1cbf7 !important",
                           },
                        }}
                        slotProps={{
                           tabs: {
                              sx: {
                                 ".Mui-selected": {
                                    color: "#ff6347 !important",
                                 },
                              },
                           },
                           day: {
                              sx: {
                                 "&.Mui-selected": {
                                    color: "#007AFF !important",
                                    background: "#a1cbf7 !important",
                                 },
                              },
                           },
                           toolbar: {
                              sx: {
                                 color: "#ff6347 !important",
                              },
                           },
                        }}
                        value={date}
                        defaultValue={initDate}
                        onChange={setDate}
                        onViewChange={(view) => setState({ view })}
                     />
                  </LocalizationProvider>
               </div>
            </div>
         </div>
         <div className="actions">
            <Button width="100%" className="btn btn-schedule-mb" disabled={state.loading} loading={state.loading} onClick={handleSubmit}>
               Hoàn thành
            </Button>
         </div>
      </div>
   );
}
