import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SocialFeed, { ESocialFeedType } from "../../models/socialFeed.model";
import User from "../../models/user.model";
import store, { RootState } from "../../state";
import CreateSocialFeed from "./SocialFeed";
import { Drawer } from "@mui/material";
import { gridBreakpoints } from "../../config";
import QnA from "../../models/qna.model";
import Community from "../../models/community.model";
import classNames from "classnames";
import { logclient, logg } from "utils/log";
import useCustomHookState from "hook/useCustomHookState";
import SchedulePost from "./SchedulePost";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import Button from "@Components/button";
import SchedulePostMb from "./SchedulePostMb";

interface IAuthRedux {
   user: User;
}
interface IProps {
   socialFeed?: SocialFeed;
   showFirstHashtag?: boolean;
   isOpenDialog?: boolean;
   menu?: ESocialFeedType;
   onCloseDialog?: () => void;
   onChangeSocialFeed?: (socialFeed: SocialFeed) => void;
   onAddSocialFeed?: (socialFeed: SocialFeed) => void;
}

function CreationPost({ isOpenDialog, menu, showFirstHashtag, socialFeed, onCloseDialog, onChangeSocialFeed, onAddSocialFeed }: IProps) {
   const router = useRouter();
   const refActions = useRef<any>();
   const refActionsQnA = useRef<any>();

   const auth = useSelector<RootState>((state) => state.auth) as IAuthRedux;

   const { state, setState } = useCustomHookState<any>({});

   const [variant, setVariant] = useState<"medium" | "large">("medium");
   const [creationElement, setCreationElement] = useState({ dialog: false, drawer: false });
   const [value, setValue] = useState(0);

   useEffect(() => {
      if (typeof window === "undefined") return;
      if (window.innerWidth < gridBreakpoints.md) {
         setCreationElement({ dialog: false, drawer: true });
      } else {
         setCreationElement({ dialog: true, drawer: false });
      }
   }, [isOpenDialog]);

   useEffect(() => {
      const menuProp = menu ?? (router?.query ? router.query.menuCreationPost : "");
      switch (menuProp) {
         case ESocialFeedType.socialFeed:
            setValue(0);
            return;
         case ESocialFeedType.qna:
            setValue(1);
            return;
      }
   }, []);
   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   const handleSchedulePost = () => {
      setState({ showSchedulePost: true });
   };

   if (!auth.user) return <></>;
   return (
      <>
         {/* Desktop */}
         {typeof window !== "undefined" && window.innerWidth > 767 ? (
            <Dialog
               onClose={onCloseDialog}
               open={creationElement.dialog ?? false}
               className={classNames("dialog-creation-post", `dialog-creation-post-${variant}`)}
            >
               {!state.showSchedulePost && (
                  <DialogTitle>
                     {!menu ? (
                        <div className="title-dialog d-flex align-items-center justify-content-center">
                           <span>{menu}</span>
                        </div>
                     ) : (
                        <>
                           <Tabs value={value} onChange={handleChange}>
                              <Tab className="post" label="Bài đăng" style={{ width: "50%" }} />
                              <Tab className="" label="Hỏi đáp" style={{ width: "50%" }} />
                           </Tabs>
                        </>
                     )}
                     <a className="btn-close" onClick={onCloseDialog}></a>
                  </DialogTitle>
               )}
               <DialogContent>
                  {state.showSchedulePost && (
                     <SchedulePost
                        value={state.scheduleTime}
                        onClose={() => {
                           setState({ showSchedulePost: false });
                           onCloseDialog?.();
                        }}
                        onBack={() => {
                           setState({ showSchedulePost: false });
                        }}
                        onSubmit={(timeStr) => {
                           if (value === 0) {
                              return refActions.current?.submit(timeStr);
                           } else {
                              return refActionsQnA.current?.submit(timeStr);
                           }
                        }}
                     />
                  )}
                  <div style={{ display: state.showSchedulePost ? "none" : "block" }}>
                     <TabPanel value={value} index={0}>
                        <CreateSocialFeed
                           refAction={refActions}
                           onClose={onCloseDialog}
                           socialFeed={socialFeed?.options?.type === ESocialFeedType.socialFeed ? socialFeed : undefined}
                           onChangeSocialFeed={onChangeSocialFeed}
                           type={value}
                           onAddSocialFeed={onAddSocialFeed}
                           onSchedulePost={handleSchedulePost}
                           showFirstHashtag={showFirstHashtag}
                        />
                     </TabPanel>
                     {/* <TabPanel value={value} index={1}>
                     <CreateCommunity onClose={onCloseDialog} />
                     <Creation type="component" />
                  </TabPanel> */}
                     <TabPanel value={value} index={1}>
                        <CreateSocialFeed
                           refAction={refActionsQnA}
                           onClose={onCloseDialog}
                           socialFeed={socialFeed?.options?.type === ESocialFeedType.qna ? socialFeed : undefined}
                           onChangeSocialFeed={onChangeSocialFeed}
                           type={value}
                           onAddSocialFeed={onAddSocialFeed}
                           onSchedulePost={handleSchedulePost}
                        />
                     </TabPanel>
                  </div>
               </DialogContent>
            </Dialog>
         ) : (
            <>
               {/* Mobile */}
               <Drawer
                  open={creationElement.drawer}
                  className="creation-post-page"
                  sx={{
                     ".MuiDrawer-paper": {
                        width: "100%",
                     },
                  }}
               >
                  <header className="spacet-main-header sticky-top">
                     <div className="top row gx-0 gx-sm-3 p-2 align-items-center" style={{ position: "relative" }}>
                        <div className="icon-back d-flex align-items-center">
                           <a
                              onClick={() => {
                                 if (state.showSchedulePost) {
                                    setState({ showSchedulePost: false });
                                 } else {
                                    onCloseDialog?.();
                                 }
                              }}
                           >
                              <ArrowBackIosNewIcon />
                           </a>
                        </div>
                        <div className="col-12 col-lg-auto d-flex justify-content-center align-items-center">
                           <h4 className="title">{state.showSchedulePost ? "Lên lịch bài đăng" : "Đăng tải chia sẻ"}</h4>
                        </div>
                     </div>
                  </header>
                  {state.showSchedulePost ? (
                     <SchedulePostMb
                        value={state.scheduleTime}
                        // onClose={() => {
                        //    setState({ showSchedulePost: false });
                        //    onCloseDialog?.();
                        // }}
                        // onBack={() => {
                        //    setState({ showSchedulePost: false });
                        // }}
                        onSubmit={(timeStr) => {
                           if (value === 0) {
                              return refActions.current?.submit(timeStr);
                           } else {
                              return refActionsQnA.current?.submit(timeStr);
                           }
                        }}
                     />
                  ) : undefined}
                  <div style={{ display: state.showSchedulePost ? "none" : "block" }}>
                     {!menu ? undefined : (
                        <>
                           <Tabs
                              value={value}
                              onChange={handleChange}
                              sx={{
                                 "& .MuiTab-root": {
                                    width: "50% !important",
                                 },
                              }}
                           >
                              <Tab disableRipple label="Bài đăng" className="tab-post" />
                              {/* <Tab disableRipple label="Tổ ấm" /> */}
                              <Tab disableRipple label="Hỏi đáp" className="tab-qa" />
                           </Tabs>
                        </>
                     )}
                     <TabPanel value={value} index={0}>
                        <CreateSocialFeed
                           refAction={refActions}
                           onClose={onCloseDialog}
                           type={value}
                           socialFeed={socialFeed}
                           showFirstHashtag={showFirstHashtag}
                           onChangeSocialFeed={onChangeSocialFeed}
                           onSchedulePost={handleSchedulePost}
                        />
                     </TabPanel>
                     {/* <TabPanel value={value} index={1}>
                           <Creation type="component" />
                        </TabPanel> */}
                     <TabPanel value={value} index={1}>
                        <CreateSocialFeed
                           refAction={refActionsQnA}
                           onClose={onCloseDialog}
                           type={value}
                           socialFeed={socialFeed}
                           onChangeSocialFeed={onChangeSocialFeed}
                           onSchedulePost={handleSchedulePost}
                        />
                     </TabPanel>
                  </div>
               </Drawer>
            </>
         )}
      </>
   );
}

export default CreationPost;
interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;
   return (
      <div className="tab-content" role="tabpanel" hidden={value !== index} {...other}>
         {children}
      </div>
   );
}
