import React from "react";
import Image from "next/image";
// import Link from "next/link";
// import { EAuthType } from "../const";
// import { executeRecaptcha } from "helpers/GoogleRecaptcha";

interface IProps {
   registerPhone: () => void;
   registerEmail: () => void;
   registerFacebook: () => void;
   registerGoogle: () => void;
   // registerApple: () => void;
   onLogin: () => void;
}

const Step1: React.FC<IProps> = ({
   registerPhone,
   registerEmail,
   registerFacebook,
   registerGoogle,
   //  registerApple,
   onLogin,
}) => {
   // const _hideAppleLoginiOSDevice = typeof window !== "undefined" && window.innerWidth < 768 && !navigator.platform.match(/iPhone|iPod|iPad/);
   return (
      <div className="register-step">
         <div className="d-flex flex-column justify-content-center">
            <div className="mobile_title d-flex d-md-none justify-content-center flex-column align-items-center">
               <Image src="/assets/img/icon/logo.svg" width={184} height={44} alt="Space T Việt Nam" title="Space T Việt Nam" />
               <h2>Nền tảng nội thất tất cả trong một</h2>
               <p>(Cộng đồng, thương mại và xây dựng)</p>
            </div>
            <h1 className="title_sign_up d-none d-md-block">Đăng ký tài khoản</h1>
            <h1 className="title_sign_up d-block d-md-none">ĐĂNG KÝ</h1>
            <div className="type_sign_up" onClick={registerPhone}>
               <div className="img">
                  <img width={18} height={18} src={"/assets/img/icon/phone_icon.svg"} alt="Đăng nhập bằng GG" />
               </div>
               <div className="text">Đăng ký với Số điện thoại</div>
            </div>
            <div className="type_sign_up" onClick={registerEmail}>
               <div className="img">
                  <img width={18} height={18} src={"/assets/img/icon/mail_icon.svg"} alt="Đăng nhập bằng GG" />
               </div>
               <div className="text">Đăng ký với địa chỉ email</div>
            </div>
            <div className="type_sign_up" onClick={registerFacebook}>
               <div className="img">
                  <img width="18px" height="18px" src="/assets/img/icon/login_FB.svg" alt="Đăng nhập bằng GG" />
               </div>
               <div className="text">Đăng ký với Facebook</div>
            </div>
            <div className="type_sign_up" onClick={registerGoogle}>
               <div className="img">
                  <img width={18} height={18} src="/assets/img/icon/login_G.svg" alt="Đăng nhập bằng GG" />
               </div>
               <div className="text">Đăng ký với Google</div>
            </div>
            {/* {_hideAppleLoginiOSDevice ? (
               ""
            ) : (
               <div className="type_sign_up" onClick={registerApple}>
                  <div className="img">
                     <img width={18} height={18} src="/assets/img/icon/apple_icon.svg" alt="Đăng nhập bằng GG" />
                  </div>
                  <div className="text">Đăng ký với Apple</div>
               </div>
            )} */}
            {/* 
    <div className="title-gg-fb">
        <div className="text">HOẶC</div>
    </div> */}

            <div className="register d-flex justify-content-center">
               Đã có tài khoản?{" "}
               <div
                  className="pointer ms-1"
                  style={{ color: "#FF6347", cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => {
                     onLogin();
                  }}
               >
                  Đăng nhập ngay
               </div>
            </div>

            <div className="noti">
               <span>Tôi đã đọc và chấp nhận các </span>
               <a style={{ color: "#252A2B" }} href="/chinh-sach/dieu-khoan-dich-vu">
                  Điều khoản sử dụng,{" "}
               </a>
               <a style={{ color: "#252A2B" }} href="/chinh-sach/chinh-sach-bao-mat">
                  Chính sách bảo mật
               </a>
               <span> và </span>
               <a style={{ color: "#252A2B" }} href={"/chinh-sach/chinh-sach-giai-quyet-khieu-nai"}>
                  chính sách giải quyết khiếu nại
               </a>
               <span> của SPACE T và đồng ý đăng ký làm thành viên </span>
            </div>
         </div>
      </div>
   );
};

export default Step1;
