import { Socket, Manager } from "socket.io-client";
import { tokenKey } from "../models/__variables";
import store from "../state";
import { verify } from "../state/action/auth";
import { handleRedirectToSite } from "utils/handleRedirectToSite";
import { TUserNotificationCases } from "models/userNotifications.model";

const domainSocket = process.env.REACT_APP_SOCKET_DOMAIN;
// const supportSocket = "https://socket.spacet.vn/support";

type socketRefs = Socket | undefined;

const socketCollection: {
   manager?: Manager;
   channels: {
      ["/"]: socketRefs;
   };
} = {
   manager: undefined,
   channels: {
      "/": undefined
   }
};

type TNamespaces = keyof typeof socketCollection.channels;

export const getSocketCollection = () => {
   const token = localStorage.getItem(tokenKey);
   const { manager, channels } = socketCollection;

   if (!token) {
      for (const [namespace, channel] of Object.entries(channels)) {
         if (!channel) {
            continue;
         }

         channel.disconnect();

         const convertedNamespace = namespace as TNamespaces;
         socketCollection.channels[convertedNamespace] && (socketCollection.channels[convertedNamespace] = undefined);
      }

      delete socketCollection.manager;

      return socketCollection;
   }

   if (!manager) {
      socketCollection.manager = new Manager(domainSocket, {
         path: "/socket",
         // extraHeaders work only for polling, not for websocket,
         // add authorize to query for websocket
         extraHeaders: {
            Authorization: `Bearer ${token}`
         },
         query: {
            authorization: `Bearer ${token}`
         },
         transports: ["websocket"]
      });
   }

   for (const [namespace, channel] of Object.entries(channels)) {
      if (channel || !socketCollection.manager) {
         continue;
      }
      const convertedNamespace = namespace as TNamespaces;
      socketCollection.channels[convertedNamespace] = socketCollection.manager.socket(convertedNamespace) ?? undefined;

      registerListeners(<Socket>socketCollection.channels[convertedNamespace], convertedNamespace);
   }

   return socketCollection;
};

const registerListeners = (socket: Socket, namespace: TNamespaces = "/") => {
   switch (namespace) {
      case "/":
         socket.on("connect_error", error => {
            console.log(error);
         });
         socket.on("connect", () => {
            console.log("=> socket connected");
         });
         socket.on("disconnect", () => {
            console.log("=> socket disconnect");
         });
         socket.on("notifications", (data: TUserNotificationCases) => {
            // console.log('notifications', data);
            // const { notification } = data || {};
            // var notiService = new Notification(notification?.title || "Space T - Thông báo", {
            //    icon: "assets/favicon.png",
            //    body: notification?.body || ""
            // });
            // notiService.onclick = function() {
            //     handleRedirectToSite(data);
            // };
            store.dispatch(verify());
         });
         break;
      default:
         break;
   }
};

// let socket:Socket;
// const socketService = () => {
//     socket = socketIOClient(domainSocket ?? "", {
//         // path: "/socket",
//         path: "/tcp/support",
//         // autoConnect: true,
//         extraHeaders: {
//             Authorization: `Bearer ${token}`
//         }
//     });

//     socket.on("connect_error", (error) => {
//         console.log(error);
//     });
//     socket.on("connect", () => {
//         console.log("=> socket connected");
//     });
//     socket.on("disconnect", () => {
//         console.log("=> socket disconnect");
//     });
//     socket.on("notifications", () => {
//         store.dispatch(verify());
//     });
// };
// // const socket = socketService();

// export const socketConnection = () => {

//     if (!socket || !socket.connected ) {
//         socketService();
//     }
// };
// export const socketDisconnect = () => {
//     socket.disconnect();
// };

// export default socketService;
