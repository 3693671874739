import React from "react";
import Image from "next/image";
import classNames from "classnames";
import { connect } from "react-redux";
import Drawer from "@mui/material/Drawer";
import { NextRouter, withRouter } from "next/router";
import { ClickAwayListener, Popover, Popper } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Link from "@Components/Link";
import Avatar from "../avatar";
import Cart from "../../storages/cart";
import User from "../../models/user.model";
import Menu, { IMenu } from "../../models/menu.model";
import MenuItemElement from "./MenuItemElement";
import HeaderMobile from "./HeaderMobile";
import MenuItem from "../../models/menuItem.model";
import AsyncMenuStorage, { MenuStorage } from "../../storages/menu";

import LayoutContainer from "../Container";
import Notifications from "../notifications";
import styles from "./_scss/index.module.scss";
import CreationPost from "../../modules/dang-bai/DialogCreateFeedPost";
import store, { RootState } from "state";
import { toggleModalAuth, toggleModalWelcome } from "state/action/auth";
import { EAuthType, EStep } from "@Components/modal/auth/const";
import SearchContent from "./components/SearchContent";
import { tokenKey } from "models/__variables";
import { ISearchSuggetions, SearchModel } from "models/search.model";
import SearchMenu from "./components/SearchMenu";
import { ESocialFeedType } from "models/socialFeed.model";
import ModalWelcome, { isShowModalWelcome } from "@Components/modal/ModalWelcome";
import { ModalBadge } from "@Components/modal/badge";
import UserNotifications, { EUserNotificationOpenTypes } from "models/userNotifications.model";
import DrawerSearch from "./DrawerSearch";
import { AppStoreContext } from "@Components/AppStoreContext";
import MenuDropdown from "./MenuDropdown";
import Navigator from "./Navigator";
import { EHeaderView } from "./header.types";
import MenuDrawerMobileV2 from "./MenuDrawerMobileV2";
import { contructionmenuId, feedmenuId, shopmenuId } from "@Config/main";
import { ModelError } from "models/model";
// import { GlobalComponentProps } from "models/types";
// import MenuDropdown from "./MenuDropdown";

interface IReduxProps {
   auth: {
      user?: User;
      notifications?: {
         unseen: number;
         cartCount: number;
      };
      bookmark?: {
         total: number;
      };
   };
}

enum EChatType {
   zalo = "zalo",
   tidio = "tidio",
}

interface IProps {
   isMobile: boolean;
   menu: any;
   menus: IMenu;
   router: NextRouter;
   hideMenuLv1?: boolean;
   subHeader?: any;
   autoHide?: boolean;
}

interface IState {
   activeIds: string[];

   levelOne?: MenuItem[];
   levelTwo?: MenuItem[];
   levelThree?: MenuItem[];

   searchKeywords?: string;
   searchResult: ISearchSuggetions;
   searchQueue: any;
   toggleSearchResult: boolean;

   activeMenuByCategoriesName: string | null;
   isActiveMenuByCategoriesName: boolean;

   shopMenu?: Menu;

   isOpenDrawerSearch: boolean;
   isOpenDrawerMenu: boolean;

   isOpenDialogCreationPost: boolean;

   anchorPopover?: HTMLAnchorElement;

   isOpenDrawerChatChannel: boolean;

   typeCreatePost: ESocialFeedType;

   bannerDownload?: boolean;
   isCreationPost?: boolean;
   typePopOver?: string;
   itemActiveNavbarMobile?: EHeaderView;

   modalBadge: { visible: boolean; dataModal?: any };
}

export class HeaderComponent extends React.Component<IReduxProps & IProps, IState> {
   static defaultProps: Partial<IReduxProps & IProps>;

   static contextType = AppStoreContext;
   context!: React.ContextType<typeof AppStoreContext>;

   private readonly _pageHiddenHeaderMobile = ["/cau-hoi-khao-sat", "/thong-tin-tai-khoan", "/gui-thanh-cong", "/search", "/shop/gio-hang"];
   private readonly _pageHiddenHeaderTopMobile = [
      "/tai-khoan",
      "/cau-hoi-khao-sat",
      "/thong-tin-tai-khoan",
      "/gui-thanh-cong",
      "/[findIdeaSlug]/[socialFeedId]",
   ];
   public _completedMenu!: MenuStorage;
   // public realTimeActiveIds: any = [];
   private _searchRef: React.RefObject<HTMLInputElement>;
   private prevScrollpos: number = 0;

   constructor(props: IReduxProps & IProps) {
      super(props);

      let pathId = "";
      const tempItemActiveNavbarMobile = this.checkHeaderRenderView();
      if (tempItemActiveNavbarMobile === EHeaderView.construction) {
         pathId = "quy-trinh-ket-noi";
      } else {
         pathId = this.props.router.pathname.split("/")[1];
      }
      const findLevelTwo = this.props.menu.levelTwo[pathId];

      // Find active menus
      const activeIds = this.findActiveMenus();

      this.state = {
         activeIds,

         levelOne: this.props.menu?.levelOne || undefined,
         levelTwo: findLevelTwo,

         searchKeywords: (this.props.router?.query?.data || "") as string,
         searchResult: {
            histories: [],
            suggestions: [],
            limit: 5,
         },

         searchQueue: null,
         toggleSearchResult: false,
         activeMenuByCategoriesName: null,
         isActiveMenuByCategoriesName: true,

         isOpenDrawerSearch: false,
         isOpenDrawerMenu: false,

         isOpenDialogCreationPost: false,

         isOpenDrawerChatChannel: false,
         typeCreatePost: ESocialFeedType.socialFeed,
         bannerDownload: true,
         isCreationPost: false,
         typePopOver: "post",
         itemActiveNavbarMobile: EHeaderView.home,

         modalBadge: { visible: false },
      };

      this._searchRef = React.createRef();
   }

   async componentDidMount() {
      this._completedMenu = await AsyncMenuStorage;

      // fetch Api
      Cart.subscribe(this);

      const tempItemActiveNavbarMobile = this.checkHeaderRenderView();
      this.setState({
         isOpenDrawerSearch: false,
         isOpenDialogCreationPost: false,
         isOpenDrawerChatChannel: false,
         itemActiveNavbarMobile: tempItemActiveNavbarMobile,
      });

      // Search suggetions
      SearchModel.fetchSuggetions({ limit: "5" }).then((res) => {
         if (res instanceof ModelError) return;
         this.setState({
            searchResult: res,
         });
      });

      // handle scroll
      if (this.props.autoHide) {
         window.addEventListener("scroll", this.handleScroll);
      }
   }

   async componentDidUpdate(prevProps: IReduxProps & any, prevState: IState) {
      // Change another page
      if (prevProps?.router?.pathname !== this.props?.router?.pathname) {
         const tempItemActiveNavbarMobile = this.checkHeaderRenderView();

         let pathId = "";
         if (tempItemActiveNavbarMobile === EHeaderView.construction) {
            pathId = "quy-trinh-ket-noi";
         } else {
            pathId = this.props.router.pathname.split("/")[1];
         }
         const findLevelTwo = this.props.menu.levelTwo[pathId];

         this.setState({
            activeIds: this.findActiveMenus(),
            levelTwo: findLevelTwo,
            itemActiveNavbarMobile: tempItemActiveNavbarMobile,
         });

         if (this.props?.router?.pathname === "/") {
            this.setState({
               isOpenDialogCreationPost: false,
            });
            return;
         }
         await this.handleChangeMenu();
      }

      // const showIntroductionCacheKey = "showIntroduction";

      // // Check show introduction popover
      // let introduction: any = localStorage.getItem(showIntroductionCacheKey);
      // try {
      //    introduction = introduction ? JSON.parse(introduction) : {};
      // } catch (error) {
      //    introduction = {};
      //    localStorage.removeItem(showIntroductionCacheKey);
      // }

      // if (this.props.auth.user && (!this.props.auth.user?.__feedCount || this.props.auth.user?.__feedCount <= 0)) {
      //    const userId = this.props.auth.user._id;
      //    const isUserShowed = introduction[userId];

      // if (!isUserShowed && userId && this.props.router.asPath === "/feed") {
      //    const isMobile = window.innerWidth < 768;
      //    const userProfilePostion = document.getElementById(!isMobile ? "post-article" : "post-article-mobile")?.getBoundingClientRect();
      //    if (userProfilePostion) {
      //       store.dispatch(
      //          togglePopoverIntroducing({
      //             visible: true,
      //             top: userProfilePostion?.y ? userProfilePostion?.y + (isMobile ? 40 : 48) : 10,
      //             left: userProfilePostion?.x ? userProfilePostion?.x - (isMobile ? 200 : 184) : 10
      //          })
      //       );
      //       localStorage.setItem(showIntroductionCacheKey, JSON.stringify({ ...introduction, [userId]: "true" }));
      //    }
      // }
      // }

      // // detect query search is change
      if (prevProps.router.query.data !== this.props.router.query.data) {
         this.setState({
            searchKeywords: this.props.router.query.data as string,
         });
      }
   }
   componentWillUnmount() {
      Cart.unsubscribe(this);
      if (this.props.autoHide) {
         window.removeEventListener("scroll", this.handleScroll);
      }
   }
   findActiveMenus() {
      const asPath = this.props.router.asPath;
      const pathname = this.props.router.pathname;

      let activeIds: string[] = [];
      if (pathname === "/") {
         activeIds = [];
      } else if (asPath.startsWith("/quy-trinh-ket-noi")) {
         activeIds = [contructionmenuId, "61a1354dd348d1719d8d1b35"];
      } else if (asPath.startsWith("/post") || asPath.startsWith("/hoi-dap")) {
         activeIds = [feedmenuId];
      } else if (pathname.startsWith("/shop/san-pham-ban-chay") || pathname.startsWith("/shop/flash-sale") || pathname.startsWith("/shop/san-pham-moi")) {
         activeIds = [shopmenuId];
      } else {
         const menuItem = Menu.findItemWithSlugV2(this.props.menus?.__items, asPath);
         activeIds = menuItem ? menuItem?.xpath?.split(";;") : [];
      }

      return activeIds;
   }
   handleScroll() {
      if (window.innerWidth < 768) {
         const currentScrollPos = window.pageYOffset;
         const navElm = document.getElementById("header");
         const hierarchical = document.getElementById("hierarchical");
         if (hierarchical) {
            if (currentScrollPos > 100) {
               hierarchical && (hierarchical.style.display = "flex");
            } else {
               hierarchical && (hierarchical.style.display = "none");
            }
         } else if (navElm) {
            if (this.prevScrollpos <= currentScrollPos) {
               navElm.style.position = "relative";
            } else {
               navElm.style.position = "sticky";
               navElm.style.top = "0";
            }
         }
         this.prevScrollpos = currentScrollPos;
      }
   }

   checkHeaderRenderView() {
      let tempItemActiveNavbarMobile: EHeaderView | undefined = undefined;
      if (this.props.router.pathname === "/" || this.props.router.pathname === "") {
         tempItemActiveNavbarMobile = EHeaderView.home;
      } else if (this.props.router.pathname.startsWith("/[")) {
         tempItemActiveNavbarMobile = EHeaderView.feed;
      } else if (this.props.router.asPath.startsWith("/shop")) {
         tempItemActiveNavbarMobile = EHeaderView.shop;
      } else if (this.props.router.asPath.startsWith("/search")) {
         tempItemActiveNavbarMobile = EHeaderView.search;
      } else if (this.props.router.asPath.startsWith("/tai-khoan")) {
         tempItemActiveNavbarMobile = EHeaderView.profile;
      } else if (this.props.router.asPath.startsWith("/tai-khoan/thong-bao")) {
         tempItemActiveNavbarMobile = EHeaderView.noti;
      } else if (
         this.props.router.pathname.startsWith("/quy-trinh-ket-noi") ||
         this.props.router.pathname.startsWith("/blog") ||
         this.props.router.pathname.startsWith("/thu-vien-anh") ||
         this.props.router.pathname.startsWith("/thu-vien-thiet-ke") ||
         this.props.router.pathname.startsWith("/cong-trinh-thuc-te") ||
         this.props.router.pathname.startsWith("/to-am-cua-toi") ||
         this.props.router.pathname.startsWith("/bao-gia") ||
         this.props.router.pathname.startsWith("/thiet-ke-2d-3d") ||
         this.props.router.pathname.startsWith("/cac-cong-ty-noi-that")
      ) {
         tempItemActiveNavbarMobile = EHeaderView.construction;
      }
      return tempItemActiveNavbarMobile;
   }

   async handleChangeMenu() {
      this.setState({
         levelOne: this._completedMenu?.LevelOne,
         isOpenDialogCreationPost: false,
      });
   }

   handleChangeMenuLevel1(menuItem: MenuItem) {
      this.setState(() => ({
         activeIds: [menuItem._id, menuItem.__childs[0]?._id],
      }));
   }

   handleChangeMenuLevel2(menuItem: MenuItem) {
      const parentLevel1 = this.state.levelOne?.find((menu) => menu._id === menuItem.parentId);
      this.setState(() => ({
         activeIds: [parentLevel1?._id as string, menuItem._id],
      }));
   }

   handleChangeMenuLevel3(parentMenuLv1?: MenuItem, parentMenuLv2?: MenuItem, menuItem?: MenuItem) {
      this.setState(() => ({
         activeIds: [parentMenuLv1?._id ?? "", parentMenuLv2?._id ?? "", menuItem?._id ?? ""],
         ["levelThree"]: undefined,
      }));
   }

   handleNavigateToHomePage() {
      this.setState({
         activeIds: [],
         // levelTwo: undefined,
      });
   }

   onFocusSearch = () => {
      this.setState({
         toggleSearchResult: true,
      });
   };

   saveKeywords = async (keywords?: string) => {
      if (!keywords) return;
      const token = localStorage.getItem(tokenKey);
      if (token) {
         await SearchModel.saveKeywords({ keywords });
      }
   };

   fetchAllSuggestions = async (keywords?: string) => {
      const result = await SearchModel.fetchSuggetions({ keywords, limit: "5" });
      if (result instanceof ModelError) return;
      this.setState({
         searchResult: result,
      });
   };

   onInputSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
         const timeOut = 200;
         const keywords = e.target.value;

         const handler = async () => {
            try {
               await this.fetchAllSuggestions(keywords);
            } catch (error) {
               console.error(error);
            }
         };
         // Clear timeout if queue pending
         if (this.state.searchQueue) {
            window.clearTimeout(this.state.searchQueue);
         }

         // Reset new timeout
         this.setState({
            ["searchKeywords"]: keywords,
            ["searchQueue"]: window.setTimeout(handler, timeOut),
         });
      } catch (error) {
         console.error(error);
      }
   };

   handleClick = () => {
      if (!this._searchRef.current?.value) {
         return;
      }
      this.props.router.push(`/search?data=${this._searchRef.current.value}`);
   };

   toggleDrawerSearch = () => {
      this.setState((prevState) => ({ isOpenDrawerSearch: !prevState.isOpenDrawerSearch }));
   };

   toogleDialogCreationPost = () => {
      this.setState({ isOpenDialogCreationPost: !this.state.isOpenDialogCreationPost });
   };

   handlePopover = (event: React.MouseEvent<HTMLAnchorElement>, type?: "chat" | "post") => {
      this.setState({ anchorPopover: event.currentTarget, typePopOver: type });
   };

   handleClosePopover = () => {
      this.setState({ anchorPopover: undefined });
   };

   handleOpenChat(type: string) {
      switch (type) {
         case EChatType.tidio:
            window.tidioChatApi.show();
            window.tidioChatApi.open();
            break;
         case EChatType.zalo:
            window.open("https://zalo.me/1171954959320860843", "_blank");
            break;
         default:
            break;
      }
      this.setState({
         anchorPopover: undefined,
         isOpenDrawerChatChannel: false,
      });
   }

   toggleDrawerConsultingChannel = () => {
      this.setState({ isOpenDrawerChatChannel: !this.state.isOpenDrawerChatChannel });
   };

   closeBannerDownload = () => {
      this.setState({ bannerDownload: false });
   };

   setPreviousURL = () => {
      if (typeof window !== "undefined") {
         if (!window.location?.href?.includes("/?login=true")) sessionStorage.setItem("previous_url", window?.location?.href);
      }
   };

   handleTypePost = (type: ESocialFeedType) => {
      this.setState({ typeCreatePost: type, anchorPopover: undefined, isOpenDialogCreationPost: true });
   };
   toogleCreatePost = () => {
      this.setState({ isCreationPost: !this.state.isCreationPost });
   };

   closeAllMenuLevel3() {
      const otherChildContainer = document.querySelectorAll<HTMLElement>(`[data-parentid], .show`);
      otherChildContainer.forEach((element) => {
         element.classList.remove("show");
         element.classList.add("hide");
      });
   }

   handleClosePopoverSearch = (event: Event | React.SyntheticEvent) => {
      if (this._searchRef.current && this._searchRef.current.contains(event.target as HTMLElement)) {
         return;
      }
      this.setState({
         toggleSearchResult: false,
      });
   };

   handleClickSearch = async (searchValue: string) => {
      this.setState({
         isOpenDrawerSearch: false,
         toggleSearchResult: false,
      });

      await this.saveKeywords(searchValue);

      const asPath = this.props.router.asPath;
      switch (asPath) {
         case (asPath.match(/^(\/shop(.*))$/) || {})?.input:
            this.props.router.push(`/search/shop?data=${searchValue}`);
            break;

         // at search checking
         case (asPath.match(/^(\/search\/feed(.*))$/) || {})?.input:
            this.props.router.push(`/search/feed?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/user(.*))$/) || {})?.input:
            this.props.router.push(`/search/user?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/anh(.*))$/) || {})?.input:
            this.props.router.push(`/search/anh?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/tat-ca-du-an(.*))$/) || {})?.input:
            this.props.router.push(`/search/tat-ca-du-an?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/thu-vien-thiet-ke(.*))$/) || {})?.input:
            this.props.router.push(`/search/thu-vien-thiet-ke?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/cong-trinh-thuc-te(.*))$/) || {})?.input:
            this.props.router.push(`/search/cong-trinh-thuc-te?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/to-am-cua-toi(.*))$/) || {})?.input:
            this.props.router.push(`/search/to-am-cua-toi?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/blog(.*))$/) || {})?.input:
            this.props.router.push(`/search/blog?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/shop(.*))$/) || {})?.input:
            this.props.router.push(`/search/shop?data=${searchValue}`);
            break;
         default:
            this.props.router.push(`/search?data=${searchValue}`);
            break;
      }
   };

   toggleDrawerMenu() {
      this.setState({ isOpenDrawerMenu: !this.state.isOpenDrawerMenu });
   }
   handleClickNotiItem = (type: EUserNotificationOpenTypes, noti: UserNotifications) => {
      if (type === EUserNotificationOpenTypes.userBadgeUpdated) {
         this.setState({
            modalBadge: {
               visible: true,
               dataModal: { noti },
            },
         });
      }
   };

   renderHeaderMenuDesktop() {
      const { auth } = this.props;

      return (
         <>
            {!auth?.user ? (
               <>
                  <a
                     id="dang-nhap"
                     onClick={() => {
                        store.dispatch(toggleModalAuth({ visible: true, type: EAuthType.signin, step: EStep.signinStep1 }));
                     }}
                     // href="/?login=true"
                     className={classNames([styles["title_regis"], styles["btn-login"]])}
                     title="Bạn chưa đăng nhập, vui lòng đăng nhập để tiếp tục"
                     style={{ cursor: "pointer" }}
                     rel="nofollow"
                  >
                     Đăng nhập
                  </a>
                  <a
                     id="dang-ky"
                     onClick={() => {
                        store.dispatch(
                           toggleModalAuth({
                              visible: true,
                              type: EAuthType.signup,
                              step: EStep.signup,
                           })
                        );
                        const showGreetingPost = isShowModalWelcome(this.props.router.asPath);
                        if (showGreetingPost) {
                           store.dispatch(toggleModalWelcome({ showGreetingPost: true }));
                        }
                     }}
                     // href={`/dang-ky${typeof window !== 'undefined' && `?regis_login=${window?.location?.href}`}`}
                     className={classNames([styles["title_regis"], styles["btn-register"]])}
                     title="Bạn chưa đăng nhập, vui lòng đăng nhập để tiếp tục"
                     style={{ cursor: "pointer" }}
                     rel="nofollow"
                  >
                     Đăng ký
                  </a>
               </>
            ) : (
               <>
                  <Link
                     href={`/tai-khoan/${auth?.user._id}?typePost=luu-tru`}
                     className={styles["cart-button"]}
                     title={!auth?.bookmark?.total || auth?.bookmark?.total < 1 ? "Bạn chưa có lưu trữ nào" : `Hiện có ${auth?.bookmark?.total} lưu trữ`}
                  >
                     <Image width={26} height={26} src="/assets/img/icon/renovation/bookmark.svg" alt="Bookmark" />
                  </Link>
                  <Link
                     href="/shop/gio-hang"
                     className={styles["cart-button"]}
                     title={
                        !auth?.notifications?.cartCount || auth?.notifications?.cartCount < 1
                           ? "Giỏ hàng của bạn hiện đang trống"
                           : `Hiện có ${auth?.notifications?.cartCount} mặt hàng`
                     }
                  >
                     <Image width={26} height={26} src="/assets/img/icon/renovation/cart.svg" alt="Giỏ hàng" />
                     {!auth?.notifications?.cartCount || auth?.notifications?.cartCount < 1 ? (
                        ""
                     ) : (
                        <div className={styles["cart-counter"]}>{auth?.notifications?.cartCount > 100 ? "100+" : auth?.notifications?.cartCount}</div>
                     )}
                  </Link>
                  <Notifications onClickItem={this.handleClickNotiItem} />
                  <Link id="user-profile" href={`/tai-khoan/${auth?.user?._id}`} title="Tài khoản" className={styles["menu-top-item"]}>
                     <Avatar size={32} url={auth?.user?.__media?.$url} />
                  </Link>
               </>
            )}

            <div className="d-flex justify-content-end ml-3">
               {(() => {
                  if (
                     this.props?.router?.pathname.startsWith("/quy-trinh-ket-noi") ||
                     this.props.router.pathname.startsWith("/blog") ||
                     this.props.router.pathname.startsWith("/thu-vien-anh") ||
                     this.props.router.pathname.startsWith("/thu-vien-thiet-ke") ||
                     this.props.router.pathname.startsWith("/cong-trinh-thuc-te") ||
                     this.props.router.pathname.startsWith("/to-am-cua-toi") ||
                     this.props.router.pathname.startsWith("/bao-gia") ||
                     this.props.router.pathname.startsWith("/thiet-ke-2d-3d") ||
                     this.props.router.pathname.startsWith("/cac-cong-ty-noi-that")
                  ) {
                     return (
                        <>
                           <div className="d-flex align-items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="1" height="24" viewBox="0 0 1 24" fill="none">
                                 <g opacity="0.5">
                                    <rect x="0.5" width="0.5" height="24" fill="#565656" />
                                 </g>
                              </svg>
                           </div>
                           <Link
                              href={`/cau-hoi-khao-sat${typeof window !== "undefined" ? `?get_consult=${window.location?.href?.replaceAll("?", "&")}` : ""}`}
                              className={classNames([styles["cta-btn"], "text-truncate", auth.user ? styles["isLogin"] : ""])}
                              title="Kết nối chuyên gia"
                              id="TuvanOnline"
                              rel="nofollow"
                              onClick={this.setPreviousURL}
                           >
                              Kết nối chuyên gia
                           </Link>
                        </>
                     );
                  }
                  if (
                     this.props.router.pathname.includes("shop") ||
                     this.props.router.pathname === "" ||
                     this.props.router.pathname === "/" ||
                     this.props.router.pathname === "/cau-hoi-khao-sat"
                  ) {
                     return;
                  }
                  return (
                     <>
                        <div className="d-flex align-items-center">
                           <svg xmlns="http://www.w3.org/2000/svg" width="1" height="24" viewBox="0 0 1 24" fill="none">
                              <g opacity="0.5">
                                 <rect x="0.5" width="0.5" height="24" fill="#565656" />
                              </g>
                           </svg>
                        </div>
                        <a
                           className={classNames([styles["cta-btn"], "c-pointer  text-truncate", auth.user ? styles["isLogin"] : ""])}
                           title="Chia sẻ"
                           onClick={(event: any) => this.handlePopover(event, "post")}
                           id="post-article"
                        >
                           <span>Tạo bài viết</span>
                           <KeyboardArrowDownIcon />
                        </a>
                     </>
                  );
               })()}
            </div>

            <ModalWelcome />
         </>
      );
   }
   renderHeaderMenuMobile = () => {
      return (
         <HeaderMobile
            view={this.state.itemActiveNavbarMobile}
            menus={this.props.menus}
            drawerMenu={this.state.isOpenDrawerMenu}
            drawerSearch={this.state.isOpenDrawerSearch}
            toggleDrawerMenu={this.toggleDrawerMenu.bind(this)}
            toggleDrawerSearch={this.toggleDrawerSearch.bind(this)}
            onCreatePost={this.toogleDialogCreationPost.bind(this)}
         />
      );
   };

   render() {
      const { auth, isMobile } = this.props;
      const { levelOne, levelTwo, activeIds, isOpenDialogCreationPost, anchorPopover, isOpenDrawerChatChannel, itemActiveNavbarMobile } = this.state;
      const isHiddenMobileHeader = this._pageHiddenHeaderMobile.findIndex((item) => this.props.router.pathname.includes(item));
      // const active = activeIds.filter(i => i && i);
      const isHiddenMobileHeaderTop = this.props.router.pathname.includes("/tai-khoan/thong-bao")
         ? -1
         : this._pageHiddenHeaderTopMobile.findIndex((item) => this.props.router.pathname.includes(item));
      const showBorderBottom = this.props.router.pathname.includes("gio-hang");

      const isShowSuperBar =
         !isMobile &&
         (this.props.router.pathname === "/" ||
            this.props.router.pathname === "" ||
            this.props.router.pathname.startsWith("/quy-trinh-ket-noi") ||
            this.props.router.pathname.startsWith("/blog") ||
            this.props.router.pathname.startsWith("/thu-vien-anh") ||
            this.props.router.pathname.startsWith("/thu-vien-thiet-ke") ||
            this.props.router.pathname.startsWith("/cong-trinh-thuc-te") ||
            this.props.router.pathname.startsWith("/to-am-cua-toi") ||
            this.props.router.pathname.startsWith("/bao-gia") ||
            this.props.router.pathname.startsWith("/thiet-ke-2d-3d") ||
            this.props.router.pathname.startsWith("/cac-cong-ty-noi-that") ||
            // this.props.router.pathname.startsWith('/chinh-sach') ||
            this.props.router.pathname.startsWith("/faq") ||
            this.props.router.pathname.startsWith("/gioi-thieu") ||
            this.props.router.asPath.startsWith("/shop"));

      return (
         <>
            {/* <ProgressBarRouter watchStr={active[active.length - 1] + itemActiveNavbarMobile} /> */}
            {/* Desktop super bar */}
            {isShowSuperBar && (
               <div className={classNames(styles["menu-top"], "d-none d-md-flex")}>
                  <LayoutContainer className="container">
                     <div className="d-flex justify-content-end align-items-center">
                        <div className={styles["grid-menu-top"]}>
                           <Link href="/noithatgiasi">
                              <span className={styles["menu-top-item"]}>Nội thất giá sỉ</span>
                           </Link>
                           <Link href="https://partner.spacet.vn/" rel="nofollow">
                              <span className={styles["menu-top-item"]}>Đối tác nội thất</span>
                           </Link>
                           <Link href="/chinh-sach/ban-hang-cung-space-t" rel="nofollow">
                              <span className={styles["menu-top-item"]}>Kênh người bán</span>
                           </Link>

                           <span className={classNames(styles["menu-top-item"], styles["menu-download"])}>
                              Tải ứng dụng
                              <div className={styles["pannel-download"]}>
                                 <Image width={190} height={190} alt="Space T" src="/assets/img/icon/QR_Code_spacet.png" />
                                 <div className={styles["list-app"]}>
                                    <a
                                       href={"https://apps.apple.com/us/app/space-t-n%E1%BB%99i-th%E1%BA%A5t-cho-t%E1%BB%95-%E1%BA%A5m/id1608834398" ?? "#"}
                                       className="d-inline-block"
                                       target="_blank"
                                       rel="noopener noreferrer nofollow"
                                       title={"Space T Application"}
                                       style={{ width: "100%" }}
                                    >
                                       <Image src="/assets/img/icon/appdownload-appstore.svg" width={90} height={26} alt="Space T" />
                                    </a>
                                    <a
                                       href={"https://play.google.com/store/apps/details?id=com.spacet.mobile" ?? "#"}
                                       className="d-inline-block"
                                       target="_blank"
                                       rel="noopener noreferrer nofollow"
                                       title={"Space T Application"}
                                       style={{ width: "100%" }}
                                    >
                                       <Image src="/assets/img/icon/appdownload-googleplay.svg" width={90} height={26} alt="Space T" />
                                    </a>
                                 </div>
                              </div>
                           </span>
                           {(() => {
                              if (
                                 this.props.router.pathname === "" ||
                                 this.props.router.pathname === "/" ||
                                 this.props.router.pathname.startsWith("/chinh-sach") ||
                                 this.props.router.pathname.startsWith("/faq") ||
                                 this.props.router.pathname.startsWith("/gioi-thieu")
                              ) {
                                 return (
                                    <span
                                       className={classNames(styles["menu-top-item"], styles["menu-download"])}
                                       onClick={(event: any) => this.handlePopover(event, "chat")}
                                    >
                                       Kết nối với chúng tôi
                                       <KeyboardArrowDownIcon />
                                    </span>
                                 );
                              } else if (
                                 this.props.router.pathname.startsWith("/shop") ||
                                 this.props.router.pathname.startsWith("/quy-trinh-ket-noi") ||
                                 this.props.router.pathname.startsWith("/blog") ||
                                 this.props.router.pathname.startsWith("/thu-vien-anh") ||
                                 this.props.router.pathname.startsWith("/thu-vien-thiet-ke") ||
                                 this.props.router.pathname.startsWith("/cong-trinh-thuc-te") ||
                                 this.props.router.pathname.startsWith("/to-am-cua-toi") ||
                                 this.props.router.pathname.startsWith("/bao-gia") ||
                                 this.props.router.pathname.startsWith("/thiet-ke-2d-3d") ||
                                 this.props.router.pathname.startsWith("/cac-cong-ty-noi-that")
                              ) {
                                 return (
                                    <div className="d-flex align-items-center  ">
                                       <Link href="tel:+84848847938" className={styles["menu-top-sub-item"]} rel="nofollow">
                                          <Image width={16} height={16} alt="Space T" src="/assets/img/icon/phone-green.svg" />
                                          <span>084-884-7938</span>
                                       </Link>
                                       <Link href="https://zalo.me/1171954959320860843" target="_blank" className={styles["menu-top-sub-item"]} rel="nofollow">
                                          <Image width={16} height={16} src="/assets/img/icon/zalo-icon.svg" alt="chat" />
                                          <span>Zalo</span>
                                       </Link>
                                       {/* <Link
                                             href="https://www.messenger.com/t/649707212106005"
                                             target="_blank"
                                             className={styles["menu-top-sub-item"]}
                                             style={{ marginRight: "0.5rem" }}
                                          >
                                             <img width={16} height={16} src="/assets/img/icon/facebook-icon.png" alt="chat" />
                                             <span>Messenger</span>
                                          </Link> */}
                                    </div>
                                 );
                              } else {
                                 return null;
                              }
                           })()}
                        </div>
                     </div>
                  </LayoutContainer>
               </div>
            )}

            <header className={classNames([styles["header"], "header-element"], styles[this.props.router.asPath?.split("/")[1]])} id="header">
               {/* Mobile device */}
               <div className={`d-block d-md-none `}>
                  {isHiddenMobileHeaderTop > -1 ? undefined : (
                     <div className={classNames(styles["top"], styles[this.props.router.asPath?.split("/")[1]])}>{this.renderHeaderMenuMobile()}</div>
                  )}
                  {isHiddenMobileHeader > -1 ? undefined : <Navigator />}

                  {/* menu level 2 */}
                  {levelTwo &&
                  itemActiveNavbarMobile !== "feed" &&
                  itemActiveNavbarMobile !== "home" &&
                  !this.props.router.pathname.startsWith("/shop") &&
                  !this.props.router.pathname.startsWith("/tai-khoan") &&
                  !this.props.router.pathname.startsWith("/search") ? (
                     <div className={styles["bottom"]}>
                        <div className={`overflow-auto px-3`}>
                           <div className="row flex-nowrap gx-0">
                              {levelTwo.map((menuItem, i) => {
                                 if (menuItem?.options?.hidden) return undefined;
                                 const parentMenu = this.state.levelOne?.find((parent) => parent._id === menuItem.parentId);

                                 return (
                                    <div className={classNames(["col-auto", styles["level-2"]])} key={i}>
                                       <MenuItemElement
                                          {...this.props}
                                          isActive={activeIds?.includes(menuItem._id)}
                                          parentMenu={parentMenu}
                                          menuItem={menuItem}
                                          className={styles["menu-item"]}
                                          onClick={() => {
                                             this.handleChangeMenuLevel2(menuItem);
                                             this.context.toggleProgressbar();
                                          }}
                                          // onMouseEnter={() => {
                                          //    this.setState({
                                          //       ["levelThree"]: menuItem.__childs ?? []
                                          //    });
                                          // }}
                                       />
                                    </div>
                                 );
                              })}
                           </div>
                        </div>
                     </div>
                  ) : undefined}
               </div>
               {/* Desktop device */}
               <div className="d-none d-md-block">
                  <div
                     className={classNames([styles["top"]], showBorderBottom ? styles["border-bottom"] : "")}
                     onMouseEnter={() => {
                        this.closeAllMenuLevel3();
                     }}
                  >
                     {/* <LayoutContainer className={classNames("container", styles["header-container"], styles[this.props.router.asPath.split("/")[1]])}> */}
                     <div className={classNames(styles["header-container"], "d-flex align-items-center justify-content-between")}>
                        <div className={classNames([styles["group-left"], "d-flex align-items-center"])} style={{ flex: "1" }}>
                           <Link
                              href="/"
                              onClick={() => {
                                 this.handleNavigateToHomePage();
                                 this.context.toggleProgressbar();
                              }}
                           >
                              <Image src="/assets/img/icon/logo.svg" width={134} height={34} alt="Space T Việt Nam" title="Space T Việt Nam" />
                           </Link>
                           <ul className={classNames([styles["main-menu"], "d-flex align-items-center list-unstyled"])} style={{ marginLeft: 24 }}>
                              {this.props.menus.__items.map((menuItem, i) => {
                                 return (
                                    <li key={i}>
                                       {["61a1332bd348d1719d8d1b32", "61a12efcd348d1719d8d1b26"].includes(menuItem._id) ? (
                                          <MenuDropdown
                                             className="position-relative"
                                             dropdownClassName={styles["top-menu-dropdown"]}
                                             menus={menuItem.__childs || []}
                                             // menus={(this.props.menus.__items.find((menu) => menu.info.slug === "shop")?.__childs || ([] as any)).filter(
                                             //    (item) => item.info.slug
                                             // )}
                                          >
                                             <MenuItemElement
                                                {...this.props}
                                                className={classNames([styles["menu-item"], "text-uppercase"])}
                                                isActive={Boolean(
                                                   activeIds?.includes(menuItem._id) && ((!menuItem.fullSlug && activeIds?.length > 1) || menuItem.fullSlug)
                                                )}
                                                menuItem={menuItem}
                                                showDropdown
                                                onClick={() => {
                                                   this.handleChangeMenuLevel1(menuItem);
                                                   this.context.toggleProgressbar();
                                                }}
                                             />
                                          </MenuDropdown>
                                       ) : (
                                          <MenuItemElement
                                             {...this.props}
                                             isNew={menuItem.info.slug === "feed" && i === 0}
                                             className={classNames([styles["menu-item"], "text-uppercase"])}
                                             isActive={Boolean(
                                                activeIds?.includes(menuItem._id) && ((!menuItem.fullSlug && activeIds?.length > 1) || menuItem.fullSlug)
                                             )}
                                             menuItem={menuItem}
                                             onClick={() => {
                                                this.handleChangeMenuLevel1(menuItem);
                                                this.context.toggleProgressbar();
                                             }}
                                          />
                                       )}
                                    </li>
                                 );
                              })}
                           </ul>

                           {/* search form for extra large device */}
                           <div className="col col-lg-none col-xl-auto" style={{ flex: "1", margin: "0 16px" }}>
                              <form
                                 className={classNames([styles["search-box"], "d-none d-xl-flex align-items-center flex-nowrap gx-0"])}
                                 onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                                    e.preventDefault();
                                    if (!this._searchRef.current?.value) {
                                       return;
                                    }
                                    this.handleClickSearch(this._searchRef.current?.value);
                                    this._searchRef.current.blur();
                                    // Clear timeout if queue pending
                                    if (this.state.searchQueue) {
                                       window.clearTimeout(this.state.searchQueue);
                                    }
                                 }}
                              >
                                 <button type="submit" className={styles["button"]} title="Tìm sản phẩm">
                                    <Image width={24} height={24} src="/assets/img/icon/search-icon-header-desktop.svg" alt="Tìm sản phẩm" />
                                 </button>
                                 <input
                                    type="text"
                                    className={classNames([styles["input"], "col"])}
                                    placeholder="Tìm kiếm"
                                    autoComplete="off"
                                    minLength={2}
                                    ref={this._searchRef}
                                    value={this.state.searchKeywords}
                                    onChange={this.onInputSearchChange.bind(this)}
                                    onFocus={this.onFocusSearch}
                                    required
                                 />

                                 <Popper
                                    className={styles["popper-search"]}
                                    open={this.state.toggleSearchResult}
                                    anchorEl={this._searchRef.current}
                                    role={undefined}
                                    placement="top-start"
                                    // transition
                                    disablePortal
                                    modifiers={{
                                       offset: {
                                          enabled: true,
                                          offset: "0, 8",
                                       },
                                    }}
                                 >
                                    <ClickAwayListener onClickAway={(e: any) => this.handleClosePopoverSearch(e)}>
                                       <div className={styles["wrap-search"]}>
                                          <SearchContent
                                             keywords={this.state.searchKeywords}
                                             searchResult={this.state.searchResult}
                                             onDeleteAllHistories={() => this.fetchAllSuggestions()}
                                             onClickSearch={this.handleClickSearch}
                                          />
                                       </div>
                                    </ClickAwayListener>
                                 </Popper>

                                 {/* {this.state.toggleSearchResult && (
                                       <div className={styles["wrap-search"]}>
                                          <SearchContent
                                             keywords={this.state.searchKeywords}
                                             searchResult={this.state.searchResult}
                                             onDeleteAllHistories={() => this.fetchAllSuggestions()}
                                          />
                                       </div>
                                    )} */}
                              </form>
                           </div>
                        </div>
                        <div className={styles["group-right"]}>
                           <div className="d-flex justify-content-end align-items-center gap-2" style={{ marginRight: "-0.5rem" }}>
                              {/* search icon for xl device */}
                              <div className="col d-md-none">
                                 <button className={classNames([styles["search-button"], "d-block"])} title="Tìm kiếm" onClick={this.toggleDrawerSearch}>
                                    <Image width={26} height={26} src="/assets/img/icon/renovation/search.svg" alt="Tìm kiếm" />
                                 </button>
                              </div>
                              {this.renderHeaderMenuDesktop()}
                           </div>
                        </div>
                     </div>
                     {/* </LayoutContainer> */}
                  </div>
               </div>

               {/* Search tab */}
               {this.props.router.pathname.startsWith("/search") && <SearchMenu />}

               {this.props.subHeader}
            </header>

            {/* Desktop show level 2 */}
            {!this.props.hideMenuLv1 &&
            this.state.levelTwo &&
            this.state.levelTwo.length > 0 &&
            this.props.router.pathname !== "/" &&
            !this.props.router.pathname.startsWith("/tai-khoan") &&
            !this.props.router.pathname.startsWith("/search") &&
            !this.props.router.pathname.startsWith("/shop") &&
            !this.props.router.pathname.startsWith("/[findIdeaSlug]") ? (
               <div className="d-none d-md-block header-element">
                  <div className={styles["bottom"]}>
                     <div className="container">
                        <div className={`overflow-auto`}>
                           <div className="row flex-nowrap gx-0">
                              {this.state.levelTwo.map((menuItem, i) => {
                                 if (!menuItem || menuItem?.options?.hidden) return undefined;
                                 const parentMenu = this.state.levelOne?.find((parent) => parent._id === menuItem.parentId);
                                 return (
                                    <div className={classNames(["col-auto", styles["level-2"]])} key={i} data-id={menuItem._id}>
                                       <MenuItemElement
                                          isActive={activeIds?.includes(menuItem._id)}
                                          parentMenu={parentMenu}
                                          menuItem={menuItem}
                                          className={styles["menu-item"]}
                                          onClick={() => this.handleChangeMenuLevel2(menuItem)}
                                          onMouseEnter={(e) => {
                                             // this.closeAllMenuLevel3();
                                             // show current
                                             const childContainer = document.querySelector<HTMLElement>(`[data-parentid="${menuItem._id}"]`);
                                             if (!childContainer) return;
                                             childContainer.classList.remove("hide");
                                             childContainer.classList.add("show");
                                          }}
                                       />
                                    </div>
                                 );
                              })}
                           </div>
                        </div>
                     </div>

                     {/* desktop menu level 3 */}
                     {/* {this.state.levelTwo.map((menuItemLv2, i) => {
                        if (menuItemLv2?.options?.hidden || !menuItemLv2.__childs || menuItemLv2.__childs?.length < 1) return undefined;
                        if (menuItemLv2?.__childs?.[0]?.info?.slug === "thu-vien-thiet-ke" || menuItemLv2?.__childs?.[0]?.info?.slug === "bep")
                           return undefined;

                        const parentMenuLevel1 = this.state.levelOne?.find((parent) => parent._id === menuItemLv2.parentId);
                        const collectMenuItem = menuItemLv2.__childs.filter((menuItem) => !menuItem?.options?.hidden);
                        if (!collectMenuItem || collectMenuItem?.length < 1) return;

                        return (
                           <div
                              key={i}
                              data-parentid={menuItemLv2._id}
                              className={classNames([styles["level-3"], "py-3", "hide"])}
                              onMouseLeave={(e) => {
                                 e.currentTarget.classList.remove("show");
                                 e.currentTarget.classList.add("hide");
                              }}
                           >
                              <div className="container">
                                 <div className="row">
                                    {collectMenuItem.map((menuItemLv3, i) => {
                                       if (menuItemLv3?.options?.hidden) return undefined;
                                       return (
                                          <div className="col-2" key={i}>
                                             <h5>
                                                <MenuItemElement
                                                   {...this.props}
                                                   parentMenu={parentMenuLevel1}
                                                   isActive={activeIds?.includes(menuItemLv3._id)}
                                                   menuItem={menuItemLv3}
                                                   onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                                      this.handleChangeMenuLevel3(parentMenuLevel1, menuItemLv2, menuItemLv3);
                                                      this.context.toggleProgressbar();
                                                   }}
                                                />
                                             </h5>
                                             {!menuItemLv3.__childs || menuItemLv3.__childs.length < 1 ? undefined : (
                                                <ul className="list-unstyled">
                                                   {menuItemLv3.__childs.map((menuItemLv4, j) => {
                                                      if (menuItemLv4.options?.hidden) return undefined;
                                                      return (
                                                         <li key={j} className="mb-2">
                                                            <MenuItemElement
                                                               {...this.props}
                                                               parentMenu={parentMenuLevel1}
                                                               isActive={activeIds?.includes(menuItemLv4._id)}
                                                               menuItem={menuItemLv4}
                                                               onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                                                  this.handleChangeMenuLevel3(parentMenuLevel1, menuItemLv2, menuItemLv4);
                                                                  this.context.toggleProgressbar();
                                                               }}
                                                            />
                                                         </li>
                                                      );
                                                   })}
                                                </ul>
                                             )}
                                          </div>
                                       );
                                    })}
                                 </div>
                              </div>
                           </div>
                        );
                     })} */}
                  </div>
               </div>
            ) : undefined}

            {/* <Navigator setState={this.setState} itemActiveNavbarMobile={this.state.itemActiveNavbarMobile} /> */}

            {/* Popover Introducing when first login show at post button */}
            {/* popover create */}
            <Popover
               className="post-popover"
               onClose={this.handleClosePopover}
               BackdropProps={{ className: "backdrop" }}
               anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
               }}
               transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
               }}
               classes={{ paper: styles["popover"] }}
               anchorEl={anchorPopover}
               open={Boolean(anchorPopover)}
            >
               <div className={styles["content"]}>
                  <span className={styles["arrow-top"]}></span>
                  {this.state.typePopOver === "post" ? (
                     <>
                        <div
                           onClick={() => {
                              if (!auth?.user) {
                                 store.dispatch(toggleModalAuth({ visible: true, type: EAuthType.signin }));
                                 this.handleClosePopover();
                              } else {
                                 this.handleTypePost(ESocialFeedType.socialFeed);
                              }
                           }}
                           className={styles["item"]}
                        >
                           <Image width={24} height={24} src="/assets/img/icon/social-feed_new.png" alt="SpaceT" />
                           <div>
                              <div className={classNames([styles["header"], "d-flex justify-content-between align-items-center"])}>
                                 <div className="text">Bài đăng</div>
                                 <div className={styles["reward-coin"]}>+ 5.000 Xu</div>
                              </div>
                              <div className={styles["description"]}>Chia sẻ gu của bạn với cộng đồng yêu nhà</div>
                           </div>
                        </div>
                        <div
                           onClick={() => {
                              if (!auth?.user) {
                                 store.dispatch(toggleModalAuth({ visible: true, type: EAuthType.signin }));
                                 this.handleClosePopover();
                              } else {
                                 this.handleTypePost(ESocialFeedType.qna);
                              }
                           }}
                           className={styles["item"]}
                        >
                           <Image width={24} height={24} src="/assets/img/icon/QA_new.png" alt="SpaceT" />
                           <div style={{ width: "100%" }}>
                              <div className={classNames([styles["header"], "d-flex justify-content-between align-items-center"])}>
                                 <div className="text">Hỏi đáp</div>
                                 {/* <div className={styles["reward-coin"]}>+ 5.000 Xu</div> */}
                              </div>

                              <div className={styles["description"]}>Xin góp ý từ cộng đồng yêu nhà</div>
                           </div>
                        </div>
                        <a href="/chinh-sach/chinh-sach-diem" className={styles["see-coin-policy"]}>
                           Chính sách Xu
                        </a>
                     </>
                  ) : (
                     <>
                        <Link href="tel:+84848847938" className={styles["item"]} rel="nofollow">
                           <Image width={16} height={16} alt="Space T" src="/assets/img/icon/phone-green.svg" />
                           <span>084-884-7938</span>
                        </Link>
                        <Link href="https://zalo.me/1171954959320860843" target="_blank" className={styles["item"]} rel="nofollow">
                           <Image width={16} height={16} src="/assets/img/icon/zalo-icon.svg" alt="chat" />
                           <span>Zalo</span>
                        </Link>
                     </>
                  )}
               </div>
            </Popover>

            {/* drawer chat */}
            <Drawer className={"drawer-consulting-channel"} anchor="bottom" open={isOpenDrawerChatChannel} onClose={this.toggleDrawerConsultingChannel}>
               <div className={"title"}>
                  <span>Chọn kênh chat</span>
                  <div className={classNames(["c-cursor icon-close"])} onClick={this.toggleDrawerConsultingChannel}>
                     <CloseIcon />
                  </div>
               </div>
               <div className={"drawer-consulting-channel-list"}>
                  <button className={classNames(["drawer-consulting-channel-item chat btn btn-link"])} onClick={() => this.handleOpenChat(EChatType.tidio)}>
                     <Image width={28} height={28} src="/assets/img/icon/chat-orange.svg" alt="consulting" />
                     <span>Tư vấn</span>
                  </button>
                  <button className={classNames(["drawer-consulting-channel-item zalo btn btn-link"])} onClick={() => this.handleOpenChat(EChatType.zalo)}>
                     <Image width={28} height={28} src="/assets/img/icon/zalo-icon.svg" alt="zalo" />
                     <span>Chat Zalo</span>
                  </button>
               </div>
            </Drawer>

            {/* Drawer menu */}
            {isMobile && (
               <MenuDrawerMobileV2 visible={this.state.isOpenDrawerMenu} menus={this.props.menus.__items} onClose={this.toggleDrawerMenu.bind(this)} />
            )}
            {/* mobile drawer search */}
            {isMobile && (
               <DrawerSearch open={this.state.isOpenDrawerSearch} onClose={this.toggleDrawerSearch.bind(this)} onSearch={this.handleClickSearch.bind(this)} />
            )}

            {/* dialog create post */}
            {isOpenDialogCreationPost ? (
               <div className="d-none d-lg-block">
                  <CreationPost isOpenDialog={isOpenDialogCreationPost} onCloseDialog={this.toogleDialogCreationPost} menu={this.state.typeCreatePost} />
               </div>
            ) : undefined}

            {/* Drawer post */}
            <Drawer className={"drawer-consulting-channel"} anchor="bottom" open={Boolean(this.state.isCreationPost)} onClose={() => this.toogleCreatePost()}>
               <div className={"title"}>
                  <span>Đăng tải chia sẻ</span>
                  <div className={classNames(["c-cursor icon-close"])} onClick={() => this.toogleCreatePost()}>
                     <CloseIcon />
                  </div>
               </div>
               <div className={"drawer-consulting-channel-list"}>
                  <div
                     onClick={() => {
                        if (auth.user) {
                           this.toogleCreatePost();
                           this.handleTypePost(ESocialFeedType.socialFeed);
                        } else {
                           store.dispatch(toggleModalAuth({ visible: true }));
                           this.toogleCreatePost();
                        }
                     }}
                     className={classNames(["drawer-consulting-channel-item chat btn btn-link"])}
                     style={{ display: "flex", color: "#1E1C1B", alignItems: "center" }}
                  >
                     <Image width={38} height={38} src="/assets/img/icon/social-feed_new.png" alt="SpaceT" />
                     <div style={{ flex: 1 }}>
                        <div className={classNames(["header", "d-flex justify-content-between align-items-center"])} style={{ fontWeight: 700 }}>
                           <div className="text">Bài đăng</div>
                           <div className={"reward-coin"}>+ 5.000 Xu</div>
                        </div>
                        <div className={"description"}>Chia sẻ gu của bạn với cộng đồng yêu nhà</div>
                     </div>
                  </div>
                  <div
                     onClick={() => {
                        if (auth?.user) {
                           this.toogleCreatePost();
                           this.handleTypePost(ESocialFeedType.qna);
                        } else {
                           store.dispatch(toggleModalAuth({ visible: true }));
                           this.toogleCreatePost();
                        }
                     }}
                     className={classNames(["drawer-consulting-channel-item chat btn btn-link"])}
                     style={{ display: "flex", color: "#1E1C1B", alignItems: "center" }}
                  >
                     <Image width={38} height={38} src="/assets/img/icon/QA_new.png" alt="SpaceT" />
                     <div style={{ flex: 1 }}>
                        <div className={classNames(["header", "d-flex justify-content-between align-items-center"])} style={{ fontWeight: 700 }}>
                           <div className={"header"} style={{ fontWeight: 700 }}>
                              Hỏi đáp
                           </div>
                           {/* <div className={"reward-coin"}>+ 5.000 Xu</div> */}
                        </div>
                        <div className={"description"}>Xin góp ý từ cộng đồng yêu nhà</div>
                     </div>
                  </div>
               </div>
               <a href="/chinh-sach/chinh-sach-diem" className={"see-coin-policy"}>
                  Chính sách Xu
               </a>
            </Drawer>

            {/* Badge */}
            <ModalBadge
               visible={this.state.modalBadge.visible}
               dataModal={this.state.modalBadge.dataModal}
               onClose={() => {
                  this.setState({ modalBadge: { ...this.state.modalBadge, visible: false } });
               }}
            />
         </>
      );
   }
}

HeaderComponent.defaultProps = {
   autoHide: true,
};

function mapStateToProps(state: RootState) {
   return { auth: state.auth };
}
function mapDispatchToProps(dispatch) {
   return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderComponent));
