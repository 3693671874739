import { useEffect, useState } from "react";
import styles from "./_scss/index.module.scss";
import { useRouter } from "next/router";
import Image from "next/image";
import classNames from "classnames";
import Link from "@Components/Link";
import { EAuthType } from "@Components/modal/auth/const";
import { useSelector } from "react-redux";
import store, { RootState } from "state";
import Avatar from "../avatar";
import { toggleModalAuth, toggleModalWelcome } from "state/action/auth";

enum ENavigatorType {
   home = "home",
   feed = "feed",
   shop = "shop",
   construction = "construction",
   noti = "noti",
   profile = "profile",
}
interface IState {
   activePath?: ENavigatorType;
}

export default function Navigator() {
   const router = useRouter();
   const auth = useSelector((state: RootState) => state.auth);
   const [state, setCurrState] = useState<IState>({});
   function setState(arg: Partial<IState>) {
      setCurrState({ ...state, ...arg });
   }
   useEffect(() => {
      let tempItemActiveNavbarMobile: ENavigatorType | undefined = undefined;
      if (router.pathname.startsWith("/[")) {
         tempItemActiveNavbarMobile = ENavigatorType.feed;
      } else if (router.asPath.startsWith("/shop")) {
         tempItemActiveNavbarMobile = ENavigatorType.shop;
      } else if (router.asPath.split("?")[0] === "/tai-khoan") {
         tempItemActiveNavbarMobile = ENavigatorType.profile;
      } else if (router.pathname === "/" || router.pathname === "") {
         tempItemActiveNavbarMobile = ENavigatorType.home;
      } else if (router.asPath.split("?")[0] === "/tai-khoan/thong-bao") {
         tempItemActiveNavbarMobile = ENavigatorType.noti;
      } else if (
         router.pathname.startsWith("/quy-trinh-ket-noi") ||
         router.pathname.startsWith("/blog") ||
         router.pathname.startsWith("/thu-vien-anh") ||
         router.pathname.startsWith("/thu-vien-thiet-ke") ||
         router.pathname.startsWith("/cong-trinh-thuc-te") ||
         router.pathname.startsWith("/to-am-cua-toi") ||
         router.pathname.startsWith("/bao-gia") ||
         router.pathname.startsWith("/thiet-ke-2d-3d") ||
         router.pathname.startsWith("/cac-cong-ty-noi-that")
      ) {
         tempItemActiveNavbarMobile = ENavigatorType.construction;
      }
      setCurrState({ activePath: tempItemActiveNavbarMobile });
   }, [router]);

   return (
      <div className={styles["bottom_navbar"]} id="navigator">
         <div className=" w-100 d-flex align-items-center justify-content-around">
            <Link
               id="home"
               onClick={() => setState({ activePath: ENavigatorType.home })}
               href={"/"}
               className={classNames([styles["navigator-item"], styles["search-button"], "d-flex d-xl-none"])}
               title="Trang chủ"
            >
               <Image
                  src={state.activePath === ENavigatorType.home ? "/assets/img/icon/home_active_navbar_icon.svg" : "/assets/img/icon/home_navbar_icon.svg"}
                  alt="hone"
                  width={24}
                  height={24}
               />
               <span className={classNames(styles["label"], state.activePath === ENavigatorType.home ? "text-primary" : "", "fs-8")}>Trang chủ</span>
            </Link>
            <Link
               id="feed"
               onClick={() => setState({ activePath: ENavigatorType.feed })}
               href={"/feed"}
               className={classNames([styles["navigator-item"], styles["search-button"], "d-flex d-xl-none"])}
               title="Feed"
            >
               <div className={styles["tag-new"]}>Mới</div>
               <Image
                  src={
                     state.activePath === ENavigatorType.feed ? "/assets/img/icon/profile_active_navbar_icon.svg" : "/assets/img/icon/profile_navbar_icon.svg"
                  }
                  alt="Mới"
                  width={24}
                  height={24}
               />
               <span className={classNames(styles["label"], state.activePath === ENavigatorType.feed ? "text-primary" : "", "fs-8")}>Cộng đồng</span>
            </Link>
            <Link
               id="shop"
               onClick={() => setState({ activePath: ENavigatorType.shop })}
               href={"/shop"}
               className={classNames([styles["navigator-item"], styles["search-button"], "d-flex d-xl-none"])}
               title="Shop"
            >
               <Image
                  src={state.activePath === ENavigatorType.shop ? "/assets/img/icon/shop_active_navbar_icon.svg" : "/assets/img/icon/shop_navbar_icon.svg"}
                  alt="Shop"
                  width={24}
                  height={24}
               />
               <span className={classNames(styles["label"], state.activePath === ENavigatorType.shop ? "text-primary" : "", "fs-8")}>Shop nội thất</span>
            </Link>
            <Link
               id="quy-trinh-ket-noi"
               onClick={() => setState({ activePath: ENavigatorType.construction })}
               href={"/quy-trinh-ket-noi"}
               className={classNames([styles["navigator-item"], styles["search-button"], "d-flex d-xl-none"])}
               title="Quy trình kết nối"
            >
               <Image
                  src={
                     state.activePath === ENavigatorType.construction
                        ? "/assets/img/icon/construction_active_navbar_icon.svg"
                        : "/assets/img/icon/construction_navbar_icon.svg"
                  }
                  alt="Quy trình kết nối"
                  width={24}
                  height={24}
               />
               <span className={classNames(styles["label"], state.activePath === ENavigatorType.construction ? "text-primary" : "", "fs-8")}>
                  Thiết kế thi công
               </span>
            </Link>

            <Link
               id="gtm-menu-notification"
               href="/tai-khoan/thong-bao"
               onClick={() => {
                  if (auth?.user) {
                     setState({ activePath: ENavigatorType.noti });
                  }
               }}
               className={classNames([styles["navigator-item"], styles["user-button"], styles["noti-button"]])}
            >
               <>
                  <Image
                     src={state.activePath === ENavigatorType.noti ? "/assets/img/icon/noti_active_navbar_icon.svg" : "/assets/img/icon/noti_navbar_icon.svg"}
                     alt="Thông báo"
                     width={24}
                     height={24}
                  />
                  {(auth?.notifications?.unseen || 0) < 1 || !auth?.notifications?.unseen ? (
                     ""
                  ) : (
                     <div className={styles["noti-counter"]}>{auth?.notifications?.unseen > 100 ? "100+" : auth?.notifications?.unseen}</div>
                  )}
               </>
               <span className={classNames(styles["label"], state.activePath === ENavigatorType.noti ? "text-primary" : "", "fs-8")}>Thông báo</span>
            </Link>

            {auth?.user?._id ? (
               <Link
                  href={`/tai-khoan/${auth?.user?._id}`}
                  title="Tài khoản"
                  className={classNames(styles["navigator-item"], styles["menu-top-item"])}
                  onClick={() => {
                     setState({ activePath: ENavigatorType.profile });
                  }}
               >
                  <Avatar size={24} url={auth?.user?.__media?.$url} />
                  <span className={classNames(styles["label"], state.activePath === ENavigatorType.profile ? "text-primary" : "", "fs-8")}>Cá nhân</span>
               </Link>
            ) : (
               <a
                  id="gtm-menu-account"
                  onClick={() => {
                     setState({ activePath: ENavigatorType.profile });
                     store.dispatch(toggleModalAuth({ visible: true, type: EAuthType.signup }));
                     store.dispatch(toggleModalWelcome({ showGreetingPost: true }));
                  }}
                  title="Tài khoản"
                  className={classNames(styles["navigator-item"], styles["menu-top-item"])}
               >
                  <Avatar size={24} url={auth?.user?.__media?.$url} />
                  <span className={classNames(styles["label"], state.activePath === ENavigatorType.profile ? "text-primary" : "", "fs-8")}>Cá nhân</span>
               </a>
            )}
         </div>
      </div>
   );
}
