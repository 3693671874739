import { Dialog, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state";
import { toggleModalError } from "state/global/actions";

interface IProps {
   onSuccess?: () => void;
}

export default function ModalError({ onSuccess }: IProps) {
   const dispatch = useDispatch();
   const { visible, title, description } = useSelector<RootState>(state => state.global.modalError) as {
      visible: boolean;
      title?: string;
      description?: string;
   };

   const handleConfirm = () => {
      dispatch(toggleModalError({ visible: false }));
      onSuccess?.();
   };

   return (
      <>
         {/* Desktop modal */}
         <Dialog open={Boolean(visible)} className="error-dialog" onClose={() => dispatch(toggleModalError({ visible: false }))}>
            <div className="dialog-content">
               {title ? <div className="error-title">{title}</div> : <div className="error-title">Có lỗi xảy ra!</div>}
               {description && <p className="error-mess mb-2" >{description}</p>}
               <button className="btn btn-primary mt-4" onClick={handleConfirm}>
                  Đồng ý
               </button>
            </div>
         </Dialog>

         {/* Mobile drawer */}
         {/* <Drawer className="download-drawer-mobile" anchor="bottom" open={Boolean(visible)} onClose={() => dispatch(toggleModalError({ visible: false }))}>
            <div className="drawer-header">
               <div className="drawer-title">Tận hưởng trải nghiệm đầy đủ trong ứng dụng</div>
               <div className="drawer-description">Khám phá điều mới mẻ mỗi ngày</div>
            </div>
            <div className="drawer-content">1</div>
         </Drawer> */}
      </>
   );
}
