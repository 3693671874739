export const LIMIT_IMAGE_UPLOAD = 50;
export const MAX_SIZE_IMAGE_UPLOAD = 10485760;

export const gridBreakpoints = {
   xs: 370,
   sm: 576,
   md: 768,
   lg: 992,
   xl: 1200,
   xxl: 1400,
};
