/**
 * Model(s)
 */
import { resizeImage } from "utils/resizeImage";
import { cloudFrontHost } from "./__variables";
import Keyword from "./keyword.model";
import { Model } from "./model";
import ProductTaggingImage from "./productTaggingImage.model";
import User from "./user.model";

export enum EMediaSortBy {
   createdAt = "createdAt",
   updatedAt = "updatedAt"
}
export enum EFileType {
   apng = "image/apng",
   avif = "image/avif",
   gif = "image/gif",
   jpeg = "image/jpeg",
   svg = "image/svg+xml",
   png = "image/png",
   webp = "image/webp"
}
export enum EMediaStatus {
   enable = "enable",
   disable = "disable"
}

export enum EMediaStorageProvider {
   Amazon = "amazon",
   Microsoft = "microsoft",
   Others = "others"
}

export type EMediaImageSize = "64" | "128" | "192" | "256" | "384" | "768" | "1024";

export enum ESolutionResizeImage {
   xs = "150",
   sm = "320",
   md = "640",
   lg = "1440"
}
export interface IPresignedPost {
   ["url"]: string;
   ["fields"]: {
      [key: string]: string;
   };
}
export interface ISizeMedia {
   width: number | undefined;
   height: number | undefined;
}

export interface IMeta {
   altText: string;
   description: string;
   title: string;
}

export enum EMediaInfoStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete"
}
export interface IMediaInfo {
   ["container"]: string | null;
   ["domain"]: string | null;
   ["originalName"]: string | null;
   ["protocol"]: string | null;
   ["size"]: number | null;
   ["width"]: number | null;
   ["height"]: number | null;
   ["extension"]: string | null;
   ["fileType"]: string | null;
   ["position"]: number | null;
   ["description"]?: string;
   ["isMigrated"]?: boolean;
   ["status"]?: EMediaInfoStatus | null;
}

export interface IMediaOptions {
   ["status"]: EMediaStatus;
   ["storageProvider"]: EMediaStorageProvider;
   ["isMigrated"]?: boolean;
}

export interface IMediaRefDoc {
   ["doc"]: User | null;
   ["model"]: string | null;
   ["status"]: string | null;
}
export interface IMedia extends Model {
   ["info"]: IMediaInfo;
   ["options"]: IMediaOptions;
   ["keywordIds"]: string[];
   ["id"]?: string | null;
   ["refDoc"]: IMediaRefDoc;
   ["ownedBy"]: {
      ["doc"]: User;
      ["model"]: string;
   } | null;

   ["__keywords"]?: Keyword[];
   ["__productTagging"]?: ProductTaggingImage[];

   ["meta"]?: IMeta | null;

   ["url"]?: string;

   ["$url"]?: any | null;
   ["$url64ResizeMax"]?: string;
   ["$url5And6ColResizeMax"]?: string;
   ["$url3And4ColResizeMax"]?: string;
   ["$url2ColResizeMax"]?: string;
   ["$url1ColResizeMax"]?: string;
}

export default class Media<T extends IMedia = IMedia> extends Model {
   public ["id"]?: string | null;
   public ["info"]!: IMediaInfo;

   public ["url"]?: string;

   public ["options"]!: IMediaOptions;
   public ["keywordIds"]!: string[];
   public ["refDoc"]!: IMediaRefDoc;
   public ["ownedBy"]!: { ["doc"]: User;["model"]: string; } | null;
   public ["__keywords"]?: Keyword[];
   public ["__productTagging"]?: ProductTaggingImage[];

   public ["meta"]?: IMeta | null;

   public ["$url"]?: string;
   public ["$url64ResizeMax"]?: string;
   public ["$url5And6ColResizeMax"]?: string;
   public ["$url3And4ColResizeMax"]?: string;
   public ["$url2ColResizeMax"]?: string;
   public ["$url1ColResizeMax"]?: string;

   constructor(data?: T) {
      super(data);
      Object.assign(this, data);
      if (!data) return;

      if (this.__keywords && this.__keywords.every(keyword => !(keyword instanceof Keyword))) {
         this.__keywords = this.__keywords.map(keyword => new Keyword(keyword));
      }

      const { $url, $url64ResizeMax, $url256ResizeMax, $url384ResizeMax, $url768ResizeMax, $url1024ResizeMax } = resizeImage(this._id, this.info, this.info?.isMigrated ?? this.options?.isMigrated)
      this.$url = $url
      this.$url64ResizeMax = $url64ResizeMax
      this.$url5And6ColResizeMax = $url256ResizeMax
      this.$url3And4ColResizeMax = $url384ResizeMax
      this.$url2ColResizeMax = $url768ResizeMax
      this.$url1ColResizeMax = $url1024ResizeMax
   }
}
