import SocialFeed, { EBannerType, ESocialFeedType } from "../../models/socialFeed.model";
import User from "../../models/user.model";
import {
   ADD_FRIST_SOCIAL_FEED,
   ADD_USER_FOLLOWING,
   REMOVE_SOCIAL_FEED,
   APPEND_USER_FOLLOWING,
   OPEN_MODAL_POST,
   REMOVE_USER_FOLLOWING,
   SOCIAL_FEED,
   UPDATE_SOCIAL_FEED,
   SET_TOTAL_SOCIAL_FEED,
   USER_FOLLOWING
} from "../type";

export function initSocialFeed(data: SocialFeed[]) {
   return {
      type: SOCIAL_FEED,
      payload: data
   };
}
export function updateSocialFeed(data: SocialFeed) {
   return {
      type: UPDATE_SOCIAL_FEED,
      payload: data
   };
}
export function removeSocialFeed(data: SocialFeed) {
   return {
      type: REMOVE_SOCIAL_FEED,
      payload: data
   };
}
export function addFirstSocialFeed(data: SocialFeed) {
   return {
      type: ADD_FRIST_SOCIAL_FEED,
      payload: data
   };
}
export function setTotalSocialFeed(data: number) {
   return {
      type: SET_TOTAL_SOCIAL_FEED,
      payload: data
   };
}
export function userFollowing(user: User[]) {
   return {
      type: USER_FOLLOWING,
      payload: user
   };
}

export function addUserFollowing(user: User) {
   return {
      type: ADD_USER_FOLLOWING,
      payload: user
   };
}

export function removeUserFollowing(user: User) {
   return {
      type: REMOVE_USER_FOLLOWING,
      payload: user
   };
}
export function appendUserFollowing(user: User[]) {
   return {
      type: APPEND_USER_FOLLOWING,
      payload: user
   };
}

export function openModalPost({
   visible,
   type,
   showFirstHashtag,
   bannerType,
   data
}: {
   visible?: boolean;
   type?: ESocialFeedType;
   showFirstHashtag?: boolean;
   bannerType?: EBannerType;
   data?: any;
}) {
   return {
      type: OPEN_MODAL_POST,
      payload: {
         visible,
         type,
         showFirstHashtag,
         data,
         bannerType
      }
   };
}
