/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const nameRegex = /[a-zA-Z][a-zA-Z ]{2,}/i;
export const emailRegex =
   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
export const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
export const phoneRegex = new RegExp(
   /^(\+84|0|84)(2(0[3-9]|1[0-6|8|9]|2[0-2|5-9]|3[2-9]|4[0-9]|5[1|2|4-9]|6[0-3|9]|7[0-7]|8[0-9]|9[0-4|6|7|9])|3[2-9]|5[5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])([0-9]{7})$/,
   "i"
);
// export const phoneRegex = /(\+84|84|0[1-9])+([0-9]{8})\b/g;
export const numberLengthRegex = new RegExp(/([0-9]{6})\b/, "i");
// export const facebookRegex = /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/i

// yup.addMethod(yup.string, 'name', function (
//     message,
// ) {
//     return this.matches(nameRegex, {
//         message,
//         excludeEmptyString: true,
//     })
// })
// yup.addMethod(yup.string, 'email', function (
//     message,
// ) {
//     return this.matches(emailRegex, {
//         message,
//         excludeEmptyString: true,
//     })
// })
// yup.addMethod(yup.string, 'url', function (
//     message,
// ) {
//     return this.matches(urlRegex, {
//         message,
//         excludeEmptyString: true,
//     })
// })
// yup.addMethod(yup.string, 'phone', function (
//     message,
// ) {
//     return this.matches(phoneRegex, {
//         message,
//         excludeEmptyString: true,
//     })
// })
// yup.addMethod(yup.string, 'facebook', function (
//     message,
// ) {
//     return this.matches(facebookRegex, {
//         message,
//         excludeEmptyString: true,
//     })
// })
export default yup;
