import { ReactNode, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import classNames from "classnames";
import yup from "core/yupPatern";
import { Controller, FieldError, useForm } from "react-hook-form";
import { ModelError, Paginate } from "models/model";
import { useTranslation } from "react-i18next";
import ProductTaggingImage from "models/productTaggingImage.model";
import { Checkbox, Popover, Tab, Tabs } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import TabPanel from "@Components/tab";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { currency } from "utils";
import Product, { IProduct } from "models/product.model";
import __variables from "models/__variables";
import Loadingv2 from "@Components/Loading/v2";
import { NotFindIcon } from "@Components/Svg";
import Checkboxv2 from "@Components/inputControl/Checkboxv2";

enum EElement {
   input = "input",
   textarea = "textarea",
   file = "file",
}
enum EName {
   productName = "productName",
   url = "url",
   price = "price",
   file = "file",
}
enum EType {
   number = "number",
   text = "text",
   email = "email",
   file = "file",
}

export interface IFormInputs {
   [EName.productName]: string;
   [EName.url]: string;
   [EName.price]: number;
   [EName.file]: File[];
}

interface IProdutParams {
   page: number;
   limit: number;
   status: "enable";
   keywords?: string;
}

const form: {
   type: EType;
   element: EElement;
   name: EName;
   placeholder: string;
   label: string;
   rows?: number;
   suffix?: string | ReactNode;
   isRequired?: boolean;
}[] = [
   {
      type: EType.text,
      element: EElement.input,
      name: EName.productName,
      placeholder: "Cây lưỡi hổ",
      label: "Tên sản phẩm",
      isRequired: true,
      rows: 2,
   },
   {
      type: EType.number,
      element: EElement.input,
      name: EName.price,
      placeholder: "250000",
      isRequired: true,
      label: "Giá",
      suffix: <span className="suffix">VND</span>,
   },
   {
      type: EType.text,
      element: EElement.textarea,
      name: EName.url,
      placeholder: "https://spacet.vn/shop/cay-luoi-ho-thai-lhtctv",
      label: "Liên kết",
      isRequired: true,
      rows: 3,
   },
   {
      type: EType.file,
      element: EElement.file,
      name: EName.file,
      placeholder: "",
      label: "",
   },
];

enum ETabType {
   internal = 0,
   external = 1,
}

interface IProductsTag {
   label: string;
   url: string;
   price: any;
   thumbnail: string;
   productId: string;
}

interface IProps {
   // socialFeedId: string,
   // socialFeedImageId: string,
   onClose: () => void;
   onSubmit: (
      type,
      { form, product, similar }: { form?: IFormInputs; product?: any; similar?: boolean }
   ) => Promise<ModelError | ProductTaggingImage | undefined>;
   anchorPosition: {
      top: number;
      left: number;
   };
}

export default function PopupTagging({ anchorPosition, onSubmit: onSubmitTagging, onClose }: IProps) {
   const { t } = useTranslation();
   // const [state, setstate] = useState<any>({
   //    params: {
   //       page: 1,
   //       limit: 50,
   //       status: "enable",
   //       keywords: ""
   //    },
   //    list: []
   // });
   const [indexTabActive, setIndexTabActive] = useState(ETabType.internal);
   const [errorsMes, setErrorsMes] = useState<ModelError>();
   const [listProduct, setListProduct] = useState<IProductsTag[]>([]);
   const [currentlistProductPaginate, setCurrentListProductPaginate] = useState<Paginate<Product, IProduct>>();
   const [fetchProductLoading, setFetchProductLoading] = useState(true);
   const [isLoadMore, setIsLoadMore] = useState(false);
   const [thumbnail, setThumbnail] = useState<{ file: any; url: any } | null>(null);
   const [isSimiler, setIsSimiler] = useState<boolean>(false);
   const [paramsProduct, setParamsProduct] = useState<IProdutParams>({
      page: 1,
      limit: 20,
      status: "enable",
      keywords: "",
   });

   // function setState(newState) {
   //    setstate(prev => ({
   //       ...prev,
   //       ...newState
   //    }))
   // }

   const schema = yup.object().shape({
      [EName.productName]: yup.string().required("Nhập tên sản phẩm"),
      [EName.price]: yup.string().required("Nhập giá sản phẩm"),
      [EName.url]: yup
         .string()
         .matches(/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm, "Enter correct url!")
         .required("Nhập liên kết sản phẩm"),
   });
   const {
      control,
      register,
      handleSubmit,
      setValue,
      formState: { errors, isSubmitting, isDirty, isValid },
      getValues,
   } = useForm<IFormInputs>({
      mode: "onChange",
      resolver: yupResolver(schema),
   });

   const onSubmitExternal = async (data: IFormInputs) => {
      try {
         const res = onSubmitTagging("external", { form: data, similar: isSimiler });
         if (res instanceof ModelError) {
            setErrorsMes(res);
            return;
         }
      } catch (error) {}
   };
   const handleSubmitInternal = (product) => {
      try {
         const res = onSubmitTagging("internal", { product, similar: isSimiler });
         if (res instanceof ModelError) {
            setErrorsMes(res);
            return;
         }
      } catch (error) {}
   };
   // const onChangeImageInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
   //    const file0 = e.target.files?.[0];
   //    try {
   //       if (!file0) {
   //          return alert("Chỉ được chọn 1 file duy nhất!");
   //       }
   //       setThumbnail({ file: file0, url: URL.createObjectURL(file0) });
   //       register("file");
   //    } catch (error) {
   //       console.error(error);
   //    }
   // };
   const fetchProduct = useCallback(async (params: IProdutParams) => {
      const paginator = await Product.paginate({
         ...params,
      });
      if (paginator instanceof ModelError) return;
      return paginator.paginate;
   }, []);
   const mappingList = useCallback((params: IProdutParams, oldList, newList: Paginate<Product, IProduct>) => {
      const list = newList?.docs?.map((doc) => {
         return {
            label: doc?.info?.title || "",
            url: `${__variables.apiDomain === "https://staging-api.spacet.vn" ? "https://staging.spacet.vn" : "https://spacet.vn"}/shop/${doc?.info?.slug}`,
            price: doc?.__variants[0]?.info?.priceAfterDiscount || doc?.__variants[0]?.info?.price || null,
            thumbnail: doc?.__images[0]?.$url ?? "",
            productId: doc?._id,
         };
      });
      setCurrentListProductPaginate(newList);
      if (newList.currentPage > 1) {
         return [...oldList, ...list];
      }
      return list;
   }, []);

   const handleFetchProduct = async (params: IProdutParams) => {
      try {
         const list = await fetchProduct(params);
         if (!list) return;
         setListProduct((prev) => mappingList(params, prev, list));
      } catch (error) {
         console.error(error);
      } finally {
         setFetchProductLoading(false);
         setIsLoadMore(false);
      }
   };

   const handleSearchProduct = debounce(async (keyword?: string) => {
      setParamsProduct((prev) => ({
         ...prev,
         page: 1,
         ["keywords"]: keyword && keyword?.length > 2 ? keyword : undefined,
      }));
   }, 1000);

   const handleOnDocumentBottom = async () => {
      if (isLoadMore || !currentlistProductPaginate?.nextPage) {
         return;
      }
      setIsLoadMore(true);
      setParamsProduct((prev) => ({ ...prev, page: paramsProduct.page + 1 }));
   };

   useEffect(() => {
      handleFetchProduct(paramsProduct);
   }, [paramsProduct]);

   const handleChangeFileImage = (e) => {
      const file0 = e.target.files?.[0];
      try {
         if (!file0) {
            return alert("Chỉ được chọn 1 file duy nhất!");
         }
         setThumbnail({ file: file0, url: URL.createObjectURL(e?.target?.files[0]) });
         register("file");
      } catch (error) {
         console.error(error);
      }
   };

   return (
      <Popover
         open={true}
         anchorReference="anchorPosition"
         anchorPosition={{ top: anchorPosition.top, left: anchorPosition.left }}
         anchorOrigin={{
            vertical: "top",
            horizontal: "left",
         }}
         transformOrigin={{
            vertical: "top",
            horizontal: "left",
         }}
         className="popover-tagging-product"
         onClose={onClose}
      >
         <div className={"popup-tagging"}>
            <Tabs
               value={indexTabActive}
               onChange={(e, newTab) => {
                  setIndexTabActive(newTab);
               }}
               className={"tabs_tagging"}
               indicatorColor={"transparent" as any}
            >
               <Tab
                  className={"tab_tagging"}
                  label={
                     <div className="d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                           <path
                              d="M10.9474 6.05314C10.3951 6.05314 9.94739 5.60543 9.94739 5.05314C9.94739 4.50086 10.3951 4.05314 10.9474 4.05314C11.4997 4.05314 11.9474 4.50086 11.9474 5.05314C11.9474 5.60543 11.4997 6.05314 10.9474 6.05314Z"
                              fill="#9F9F9F"
                           />
                           <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.56072 1.34648H8.61406L12.6141 1.63314C13.5457 1.71651 14.284 2.45485 14.3674 3.38648L14.6607 7.38648C14.6976 7.75454 14.5708 8.12019 14.3141 8.38648L8.68739 14.0131C8.44973 14.2421 8.13063 14.3668 7.80072 14.3598C7.29599 14.3554 6.81473 14.146 6.46739 13.7798L2.22072 9.53981C1.56106 8.99293 1.45755 8.0199 1.98739 7.34648L7.64739 1.67981C7.88624 1.45275 8.20454 1.3283 8.53406 1.33314L8.56072 1.34648ZM8.53406 2.41981C8.495 2.40753 8.45311 2.40753 8.41406 2.41981L2.78072 8.05314C2.72624 8.30301 2.81948 8.56201 3.02072 8.71981L7.28072 12.9798C7.42106 13.1321 7.61419 13.225 7.82072 13.2398H7.93406L13.5474 7.57981C13.5653 7.53257 13.5653 7.48039 13.5474 7.43314L13.2541 3.43314C13.2017 3.07035 12.9219 2.78244 12.5607 2.71981L8.56072 2.43314L8.53406 2.41981Z"
                              fill="#9F9F9F"
                           />
                        </svg>
                        Space T Shop
                     </div>
                  }
               />
               <Tab
                  className={"tab_tagging"}
                  label={
                     <div className="d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                           <path
                              d="M10.3854 10.8288L8.49978 12.7144C7.84767 13.3666 7.06199 13.6926 6.14276 13.6926C5.22352 13.6926 4.43784 13.3666 3.78573 12.7144C3.13362 12.0623 2.80757 11.2767 2.80757 10.3574C2.80757 9.43818 3.13362 8.65251 3.78573 8.0004L5.67135 6.11478L6.61416 7.05759L4.72854 8.94321C4.33571 9.33605 4.13929 9.80745 4.13929 10.3574C4.13929 10.9074 4.33571 11.3788 4.72854 11.7716C5.12138 12.1645 5.59278 12.3609 6.14276 12.3609C6.69273 12.3609 7.16413 12.1645 7.55697 11.7716L9.44259 9.88602L10.3854 10.8288ZM7.08556 10.3574L6.14276 9.41461L9.91399 5.64338L10.8568 6.58619L7.08556 10.3574ZM11.3282 9.88602L10.3854 8.94321L12.271 7.05759C12.6639 6.66475 12.8603 6.19335 12.8603 5.64338C12.8603 5.09341 12.6639 4.622 12.271 4.22916C11.8782 3.83633 11.4068 3.63991 10.8568 3.63991C10.3068 3.63991 9.83542 3.83633 9.44259 4.22916L7.55697 6.11478L6.61416 5.17197L8.49978 3.28635C9.15189 2.63425 9.93756 2.30819 10.8568 2.30819C11.776 2.30819 12.5617 2.63425 13.2138 3.28635C13.8659 3.93846 14.192 4.72414 14.192 5.64338C14.192 6.56262 13.8659 7.34829 13.2138 8.0004L11.3282 9.88602Z"
                              fill="#9F9F9F"
                           />
                        </svg>
                        Liên kết
                     </div>
                  }
               />
            </Tabs>
            <TabPanel value={indexTabActive} index={ETabType.internal}>
               <div className="wrap-checkbox">
                  <Checkboxv2 label="Similar" checked={isSimiler} onChange={() => setIsSimiler(!isSimiler)} />
               </div>
               <div className="wrap-input">
                  <input placeholder="Nhập tên sản phẩm hoặc SKU" onChange={(e) => handleSearchProduct(e.target.value)} />
               </div>
               <ProductList
                  loading={fetchProductLoading}
                  isLoadMore={isLoadMore}
                  listProduct={listProduct}
                  onClickItem={(product) => {
                     handleSubmitInternal(product);
                  }}
                  handleContainerOnBottom={handleOnDocumentBottom}
               />
            </TabPanel>
            <TabPanel value={indexTabActive} index={ETabType.external}>
               <form className={"form-tagging"} onSubmit={handleSubmit(onSubmitExternal)}>
                  {form.map((elm, i) => {
                     return (
                        <div
                           key={i}
                           className={`submission__form-item ${"form-item"} ${
                              (errors?.[`${elm.name}`] as FieldError) || errorsMes?.errors?.[`${elm.name}`] ? "error" : ""
                           }`}
                        >
                           <label className={`label ${"label"}`}>
                              {elm.label ?? ""}
                              {elm.isRequired ? <span className={"required"}>*</span> : undefined}
                           </label>
                           {
                              {
                                 [EElement.input]: (
                                    <Controller
                                       control={control}
                                       name={elm.name}
                                       render={({ field: { onChange, value }, fieldState: { error } }) => {
                                          return (
                                             <div className="wrap_input">
                                                <input
                                                   type={elm.type}
                                                   className={`form-control ${"form-control"}`}
                                                   placeholder={elm.placeholder}
                                                   value={value ? value.toString() : ""}
                                                   onChange={onChange}
                                                   onKeyUp={(e) => {
                                                      onChange(value ? String(value) : "");
                                                   }}
                                                />
                                                {elm.suffix}
                                             </div>
                                          );
                                       }}
                                    />
                                 ),
                                 [EElement.textarea]: (
                                    <textarea
                                       style={{ resize: "none" }}
                                       rows={elm.rows ?? 1}
                                       className={`form-control ${"form-control"}`}
                                       placeholder={elm.placeholder}
                                       {...register(elm.name)}
                                    />
                                 ),
                                 [EElement.file]: (
                                    <>
                                       {thumbnail ? (
                                          <div className={"form_upload"}>
                                             <img className="thumbnail_img" src={thumbnail?.url} alt="Space T" />
                                             <div className={"info-tag"}>
                                                <div className="name">{thumbnail?.file?.name}</div>
                                                <div className="btn_change_file">Đổi ảnh</div>
                                             </div>
                                             <div
                                                className="icon_delete"
                                                onClick={() => {
                                                   setThumbnail(null);
                                                   setValue("file", null as never);
                                                }}
                                             >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                                   <path
                                                      d="M0.167579 10.1004C-0.0590861 9.88019 -0.0526099 9.50457 0.167579 9.28438L4.31232 5.13317L0.167579 0.988431C-0.0526099 0.768242 -0.0526099 0.399101 0.167579 0.172436C0.387768 -0.0607054 0.769861 -0.0542292 0.99005 0.172436L5.13479 4.31717L9.27952 0.172436C9.49971 -0.0542292 9.87533 -0.0542292 10.102 0.172436C10.3287 0.392625 10.3222 0.768242 10.102 0.988431L5.95726 5.13317L10.102 9.28438C10.3222 9.50457 10.3222 9.87371 10.102 10.1004C9.88181 10.327 9.49971 10.3206 9.27952 10.1004L5.13479 5.95564L0.99005 10.1004C0.769861 10.3206 0.394244 10.3206 0.167579 10.1004Z"
                                                      fill="#666666"
                                                   />
                                                </svg>
                                             </div>
                                             <input
                                                type="file"
                                                className={"upload"}
                                                accept="image/png,image/jpeg,image/jpg, image/webp"
                                                placeholder="Chọn hình cho sản phẩm"
                                                {...register("file", {
                                                   onChange: (e) => handleChangeFileImage(e),
                                                })}
                                             />
                                          </div>
                                       ) : (
                                          <div className={"form_upload"}>
                                             <div className="thumbnail_img">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                   <path
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                      d="M14.23 2H10C5.58172 2 2 5.58172 2 10V14.24C2 18.6583 5.58172 22.24 10 22.24H14.23C18.6483 22.24 22.23 18.6583 22.23 14.24V10C22.23 5.58172 18.6483 2 14.23 2ZM8.12 6.12C9.22457 6.12 10.12 7.01543 10.12 8.12C10.12 9.22457 9.22457 10.12 8.12 10.12C7.01543 10.12 6.12 9.22457 6.12 8.12C6.12 7.01543 7.01543 6.12 8.12 6.12ZM15.51 20.12C18.3411 19.0627 20.2212 16.3621 20.23 13.34L20.2 11.62C20.2 11.21 20.12 10.44 20.12 10.44H18.49C14.7164 10.4515 11.2706 12.5862 9.58 15.96C8.35291 14.863 6.76591 14.2546 5.12 14.25H3.91C3.82074 16.5747 5.08341 18.7415 7.15 19.81C7.88878 20.2036 8.71293 20.4096 9.55 20.41H13.72C14.329 20.4187 14.9349 20.3206 15.51 20.12Z"
                                                      fill="#E2E4EB"
                                                   />
                                                </svg>
                                             </div>
                                             <label className={"upload__btn"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                   <path
                                                      d="M8.46041 2.19386L12.4565 6.08053C12.7281 6.33502 12.7372 6.75504 12.4771 7.02053C12.3484 7.14674 12.1732 7.21773 11.9904 7.21773C11.8077 7.21773 11.6325 7.14674 11.5038 7.02053L8.65919 4.27386V11.3605C8.65919 11.7287 8.3523 12.0272 7.97375 12.0272C7.59519 12.0272 7.28831 11.7287 7.28831 11.3605V4.27386L4.4643 7.02053C4.19234 7.24705 3.78695 7.23182 3.53377 6.98557C3.28059 6.73932 3.26493 6.34504 3.49783 6.08053L7.49394 2.19386C7.76129 1.93538 8.19306 1.93538 8.46041 2.19386Z"
                                                      fill="#9F9F9F"
                                                   />
                                                   <path
                                                      d="M4.54655 12.6939H11.4009C11.7795 12.6939 12.0864 12.9923 12.0864 13.3605C12.0864 13.7287 11.7795 14.0272 11.4009 14.0272H4.54655C4.16799 14.0272 3.86111 13.7287 3.86111 13.3605C3.86111 12.9923 4.16799 12.6939 4.54655 12.6939Z"
                                                      fill="#9F9F9F"
                                                   />
                                                </svg>{" "}
                                                Chọn hình ảnh cho thẻ
                                             </label>
                                             <input
                                                type="file"
                                                className={"upload"}
                                                accept="image/png,image/jpeg,image/jpg, image/webp"
                                                placeholder="Chọn hình cho sản phẩm"
                                                {...register("file", {
                                                   onChange: (e) => handleChangeFileImage(e),
                                                })}
                                             />
                                          </div>
                                       )}
                                    </>
                                 ),
                              }[elm.element]
                           }

                           {Object.entries(errors).map(([key, value], i) =>
                              key === (elm.name as string) ? (
                                 <p className="text-error mt-2 mb-1" key={i}>
                                    {value.message ?? ""}
                                 </p>
                              ) : undefined
                           )}
                           {errorsMes?.errors?.[`${elm.name}`] ? (
                              <p className="text-error">{t((errorsMes?.errors?.[`${elm.name}`] as unknown as string) ?? "")}</p>
                           ) : undefined}
                        </div>
                     );
                  })}
                  <Checkboxv2 className="mt-3 mb-1" label="Similar" checked={isSimiler} onChange={() => setIsSimiler(!isSimiler)} />
                  <div className="d-flex gap-2 w-100">
                     {/* <button className={classNames("btn", "btn-cancel")} onClick={onClose}>
                        Hủy
                     </button> */}
                     <button className={classNames("btn", "btn-submit")} type="submit" disabled={!isDirty || !isValid}>
                        Tag
                     </button>
                  </div>
               </form>
            </TabPanel>
         </div>
      </Popover>
   );
}

function ProductList({ loading, isLoadMore, listProduct, onClickItem, handleContainerOnBottom }) {
   const containerRef = useBottomScrollListener(handleContainerOnBottom, { offset: 600 });
   return (
      <div className="product-list" ref={containerRef as any}>
         {loading ? (
            <div className="center-absolute">
               <Loadingv2 scale="small" />
            </div>
         ) : (
            <>
               {listProduct && listProduct.length > 0 ? (
                  <>
                     {listProduct?.map((product) => (
                        <div
                           className="item d-flex"
                           key={product._id}
                           onClick={() => {
                              onClickItem(product);
                           }}
                        >
                           <img width="50" height="45" src={product?.thumbnail} className="mr-1" />
                           <div className="product-info">
                              <div className="text text-truncate">{product?.label}</div>
                              {product.price > 0 ? (
                                 <div className="price bold">
                                    {currency(product.price)}
                                    <span className="underline">đ</span>
                                 </div>
                              ) : (
                                 <span className="price bold">Liên hệ</span>
                              )}
                           </div>
                        </div>
                     ))}
                     {isLoadMore && (
                        <div className="center-horizontal py-2">
                           <Loadingv2 scale="small" />
                        </div>
                     )}
                  </>
               ) : (
                  <div className="product-empty ">
                     <NotFindIcon />
                     <p>Không tìm thấy thẻ</p>
                     <p className="span">{`Trống :(`}</p>
                  </div>
               )}
            </>
         )}
      </div>
   );
}
